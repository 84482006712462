import styled from "styled-components";
import { colors } from "@/helpers";
import { Icon } from "@/packages/icon/Icon";

export const StyledCalendarFilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  .react-datepicker-popper {
    z-index: 22;
  }
`;

export const StyledFormLayout = styled.div`
  display: grid;
  gap: 32px;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-template-columns minmax(188px, max-content) 40px auto;
  margin-bottom: 24px;
`;

export const StyledDatepickerWrapper = styled.div`
  display: grid;
  gap: 10px;
  align-items: end;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
`;

export const StyledDash = styled.span`
  width: 12px;
  height: 1px;
  margin-bottom: 14px;
  background-color: ${colors.gray4};
`;

export const StyledButtonsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  gap: 16px;
`;

export const StyledSelectWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: center;
  width: 150px;
`;

export const StyledIconContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: start;
  padding-top: 16px;
`;
