import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useEffect } from "react";

export interface ICalendarFilterFormValues {
  modelIds: string[];
  endPeriodDate: Date | null;
  startPeriodDate: Date | null;
  endPeriodDateForExport: Date | null;
  startPeriodDateForExport: Date | null;
}

export const DEFAULT_CALENDAR_FILTER_FORM_VALUES: ICalendarFilterFormValues = {
  modelIds: [],
  startPeriodDate: null,
  endPeriodDate: null,
  startPeriodDateForExport: new Date(),
  endPeriodDateForExport: null,
};

export const formSchema = yup.object({
  startPeriodDateForExport: yup
    .date()
    .nullable()
    .required("Необходимо выбрать дату начала временного периода"),
  // endPeriodDateForExport: yup
  //   .date()
  //   .nullable()
  //   .required("Необходимо ввести дату конца временного периода"),
});

export type TOnSubmitCalendarFilterFormCb = (
  values: ICalendarFilterFormValues
) => void;

export const useCalendarFilterForm = (options: {
  initValues: ICalendarFilterFormValues | undefined;
  onSubmit: TOnSubmitCalendarFilterFormCb;
}) => {
  const form = useForm<ICalendarFilterFormValues>({
    resolver: yupResolver(formSchema),
    mode: "onChange",
    defaultValues: DEFAULT_CALENDAR_FILTER_FORM_VALUES,
  });

  const { initValues, onSubmit } = options;

  const onSubmitHandler = form.handleSubmit(async (data) => {
    onSubmit(data);
  });

  const onClearHandler = () => {
    form.reset();
    onSubmit(form.getValues());
  };

  useEffect(() => {
    if (initValues?.modelIds && initValues?.startPeriodDateForExport) {
      form.reset(initValues, { keepDefaultValues: true });
      onSubmit(form.getValues());
    }
  }, []);

  return {
    form,
    onSubmitHandler,
    onClearHandler,
  };
};
