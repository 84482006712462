import { PermissionsAdapter } from '@/adapters/shared/PermissionsAdapter';
import { TPermissionResourcesName } from '@/helpers/api/eventLogs/types';
import {
    useExportMaintenanceGrid,
    useGetMaintenanceGridById,
    useGetMaintenanceGridByModificationId,
} from '@/helpers/api/maintenanceGrids/grids/hooks';
import { useGetEquipmentList } from '@/helpers/api/modifications/hooks';
import { getAdaptSelectOptions, handleFileLoad } from '@/helpers/utils';
import { ContextMaintenanceGrids, MAINTENANCE_GRIDS } from '@/hooks/contexts/useMaintenanceGrids';
import { ContextProfile } from '@/hooks/contexts/useProfile';
import { useCopyCurrentUrl } from '@/hooks/useCopyCurrentUrl';
import { useNotification } from '@/hooks/useNotification';
import { DashboardLayout } from '@/layouts/DashboardLayout';
import { Button } from '@/packages/button/Button';
import { SelectField } from '@/packages/formElements/fields/SelectField';
import { Switch } from '@/packages/formElements/inputs/switch';
import { IconButton } from '@/packages/icon-button/IconButton';
import { Paragraph } from '@/packages/paragraph/Paragraph';
import { ICreatedRow, TableMaintenanceGrids } from '@/packages/tableMaintenanceGrids';
import { getTableHeaders } from '@/packages/tableMaintenanceGrids/utils';
import { Space } from 'antd';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import {
    createGsmTableRow,
    createSpareTableRow,
    createWorkTableRow,
    getAdaptMaintenanceNumbersForTable,
    getMaintenanceNumbers,
} from './helpers/utils';
import {
    StyledButton,
    StyledButtons,
    StyledContentTitle,
    StyledContentTop,
    StyledMaintenanceGridsDetails,
    StyledRoleButtons,
    StyledInfoWrapper,
} from './index.styles';
import { TGsmTableData, TSpareTableData, TWorkTableData } from './index.types';
import { useTranslation } from 'react-i18next';
import { useModal } from '@/hooks/useModal';
import { InfoModal } from '@/pages/MaintenanceGridsDetailsView/infoModal';

const StyledTableMaintenanceGrids = styled((props) => (
    <TableMaintenanceGrids<TWorkTableData | TGsmTableData | TSpareTableData>
        {...props}
    />
))`
    margin-left: -32px;
    margin-right: -32px;
`;

const PAGE_PERMISSION_NAME: TPermissionResourcesName = 'maintenance-grid';

const MaintenanceGridsDetails: FC = () => {
    const { t } = useTranslation();
    const params = useParams();
    const navigate = useNavigate();
    const copyCurrentUrl = useCopyCurrentUrl({
        successMessage: t('maintenanceGrids.copyCurrentUrl') || '',
    });

    const { createNotificationError, createNotificationSuccess } = useNotification();

    const activeMaintenanceGridId = params.id as string;

    const [ isStatusActive, setIsStatusActive ] = useState<boolean>(false);
    const [ worksTableData, setWorksTableData ] = useState<TWorkTableData[]>([]);
    const [ gsmTableData, setGsmTableData ] = useState<TGsmTableData[]>([]);
    const [ sparesTableData, setSparesTableData ] = useState<TSpareTableData[]>([]);
    const [ creatingModificationIds, setCreatingModificationIds ] = useState<
        string[]
    >([]);
    const [ isReadOnly, setIsReadOnly ] = useState<boolean>(false);
    const [ errors, setErrors ] = useState({
        modifications: '',
        works: '',
        gsm: '',
        spares: '',
    });

    const {
        state: {
            creatingMaintenanceGridForm,
            works,
            worksDefault,
            gsmList,
            gsmListFilters,
            worksListFilters,
            gsmListDefault,
            spares,
            sparesListFilters,
            isMaintenanceGridUpdating,
            createMaintenanceGridError,
            updateMaintenanceGridError,
        },
        mutations: { setSearchSpare, setSearchGrids, setSearchWorks },
        actions: { createMaintenanceGrid, updateMaintenanceGrid },
    } = useContext<MAINTENANCE_GRIDS>(ContextMaintenanceGrids);

    const {
        state: { profile },
    } = useContext(ContextProfile);

    const { mutateAsync: exportMaintenanceGrid } = useExportMaintenanceGrid();

    const modelDuplicateEquipments =
        creatingMaintenanceGridForm?.modelDuplicate?.equipments || [];

    const {
        data: maintenanceGridResponse,
        refetch: refetchMaintenanceGridResponse,
    } = useGetMaintenanceGridById({
        id: activeMaintenanceGridId,
        isEnabled: Boolean(activeMaintenanceGridId),
    });

    const {
        data: maintenanceGridByModificationResponse,
        error: maintenanceGridByModificationError,
    } = useGetMaintenanceGridByModificationId({
        id: modelDuplicateEquipments[0]?.id,
        isEnabled: true,
    });

    const maintenanceGrid =
        maintenanceGridByModificationResponse?.data ||
        maintenanceGridResponse?.data ||
        null;

    const { data: equipments, refetch: refetchEquipments } = useGetEquipmentList({
        generationId:
            creatingMaintenanceGridForm?.model.generation?.id ||
            // @ts-ignore
            maintenanceGrid?.modifications[0]?.generation?.id,
    });

    const isEditedMaintenanceGrid = Boolean(activeMaintenanceGridId);
    const title = isEditedMaintenanceGrid
        ? t('maintenanceGrids.gridsModel.viewGrid.edit_2') || ''
        : t('maintenanceGrids.gridsModel.viewGrid.creation');
    const profileRoleType = profile?.type || 'dealer';
    const isDealer = profile?.type === 'dealer';

    const worksTableHeaders = getTableHeaders(
        { isMultiColor: true },
        3,
        'Код работ',
        isDealer,
        false,
    );
    const gsmTableHeaders = getTableHeaders(
        {
            isMultiColor: true,
        },
        3,
        'Код ГСМ',
        isDealer,
        false,
    );
    const partsTableHeaders = getTableHeaders(
        {
            isMultiColor: true,
        },
        3,
        'Код запчастей',
        isDealer,
        false,
    );

    const worksOptions = useMemo(
        () =>
            worksListFilters?.data
                            .map((work) => ({
                                ...work,
                                label: `${work.code} - ${work.name}`,
                                value: work.id,
                            }))
                            .filter((option) => {
                                const selectedIds = worksTableData.map((work) => work.workId);

                                return !selectedIds.includes(String(option.value));
                            }),
        [ worksListFilters, worksTableData ],
    );
    const gsmOptions = useMemo(
        () =>
            gsmListFilters?.data
                          .map((gsm) => ({
                              ...gsm,
                              label: `${gsm.code} - ${gsm.name}`,
                              value: gsm.id,
                          }))
                          .filter((option) => {
                              const selectedIds = gsmTableData.map((gsm) => gsm.materialId);

                              return !selectedIds.includes(String(option.value));
                          }),
        [ gsmListFilters, gsmTableData ],
    );
    const sparesOptions = useMemo(
        () =>
            getAdaptSelectOptions({
                options:
                    sparesListFilters?.data.map((item) => ({
                        ...item,
                        name: `${item.code} - ${item.name}`,
                    })) || [],
                valueKey: 'code',
            })
                .filter((option) => {
                    const selectedIds = sparesTableData.map((spare) => spare.spareCode);

                    return !selectedIds.includes(String(option.value));
                }),
        [ sparesListFilters, sparesTableData ],
    );
    const equipmentOptions = useMemo(
        () =>
            getAdaptSelectOptions({
                options:
                    equipments?.data.data.map((equipment) => ({
                        ...equipment,
                        name: equipment.fullName,
                    })) || [],
            }),
        [ equipments?.data.data ],
    );

    const idsSelectValues = equipmentOptions
        .map((elem) => elem.id)
        .filter((elem) => creatingModificationIds.indexOf(elem) !== -1);

    const handleModificationsChange = (modificationIds: string[]) => {
        setCreatingModificationIds(modificationIds);
        errors.modifications &&
        setErrors((prev) => ({ ...prev, modifications: '' }));
    };

    const handleWorkTableRowCreate = (rowData: ICreatedRow) => {
        setWorksTableData((prev) => {
            const newRow = createWorkTableRow(rowData);
            return [ ...prev, newRow ];
        });
    };

    const handleWorkTableRowChange = (_, row: TWorkTableData) => {
        setWorksTableData((prev) =>
            prev.map((prevWork) => (prevWork.workId === row?.workId ? row : prevWork)),
        );
    };

    const handleWorkTableRowDelete = (row: TWorkTableData) => {
        setWorksTableData((prev) =>
            //@ts-ignore
            prev.filter((prevWork) => prevWork.workId !== row?.workId),
        );
    };

    const handleGsmTableRowCreate = (rowData: ICreatedRow) => {
        setGsmTableData((prev) => {
            const newRow = createGsmTableRow(rowData);
            return [ ...prev, newRow ];
        });
    };

    const handleGsmTableRowChange = (_, row: TGsmTableData) => {
        setGsmTableData((prev) =>
            prev.map((prevWork) =>
                prevWork.materialId === row?.materialId ? row : prevWork,
            ),
        );
    };

    const handleGsmTableRowDelete = (row: TGsmTableData) => {
        setGsmTableData((prev) =>
            prev.filter((prevWork) => prevWork.materialId !== row?.materialId),
        );
    };

    const handleSpareTableRowCreate = (rowData: ICreatedRow) => {
        setSparesTableData((prev) => {
            const newRow = createSpareTableRow(rowData);
            return [ ...prev, newRow ];
        });
    };

    const handleSpareTableRowChange = (_, row: TSpareTableData) => {
        setSparesTableData((prev) =>
            prev.map((prevSpare) =>
                prevSpare.spareCode === row?.spareCode ? row : prevSpare,
            ),
        );
    };

    const handleSpareTableRowDelete = (row: TSpareTableData) => {
        setSparesTableData((prev) =>
            prev.filter((prevSpare) => prevSpare.spareCode !== row?.spareCode),
        );
    };

    const [ creatingModificationIdsLabel, setCreatingModificationIdsLabel ] =
        useState<string>('');

    const handleSubmitButtonClick = () => {
        if (creatingModificationIds.length === 0)
            setErrors((prev) => ({
                ...prev,
                modifications: t(
                    'maintenanceGrids.gridsModel.viewGrid.modificationMust',
                ),
            }));
        if (worksTableData.length === 0)
            setErrors((prev) => ({
                ...prev,
                works: t('maintenanceGrids.gridsModel.viewGrid.workAdd'),
            }));
        // if (gsmTableData.length === 0)
        //   setErrors((prev) => ({
        //     ...prev,
        //     gsm: "Необходимо добавить хотя бы одну ГСМ",
        //   }));
        // if (sparesTableData.length === 0)
        //   setErrors((prev) => ({
        //     ...prev,
        //     spares: "Необходимо добавить хотя бы одну запчасть",
        //   }));

        const works = worksTableData.map((work) => ({
            workId: work.workId,
            amount: work.quantity,
            // maintenanceNumbers: getMaintenanceNumbers({ data: work }),
            maintenanceNumbers: getMaintenanceNumbers({
                data: work,
                isMultiColorNumbers: true,
                idKey: 'workType',
            }),
        }));

        const materials = gsmTableData.map((gsm) => {
            return {
                materialId: gsm.materialId,
                amount: gsm.quantity,
                // maintenanceNumber: getMaintenanceNumbers({ data: gsm }),
                maintenanceNumbers: getMaintenanceNumbers({
                    data: gsm,
                    isMultiColorNumbers: true,
                    idKey: 'type',
                }),
            };
        });

        const spares = sparesTableData.map((spare) => {
            return {
                spareCode: spare.spareCode,
                amount: spare.quantity,
                // maintenanceNumber: getMaintenanceNumbers({ data: spare }),
                maintenanceNumbers: getMaintenanceNumbers({
                    data: spare,
                    isMultiColorNumbers: true,
                    idKey: 'type',
                }),
            };
        });

        if (
            creatingModificationIds.length === 0 &&
            works.length === 0
            // materials.length === 0 ||
            // spares.length === 0
        )
            return;

        const data = {
            modificationIds: creatingModificationIds,
            works,
            materials,
            spares,
            isActive: isStatusActive,
        };

        isEditedMaintenanceGrid
            ? updateMaintenanceGrid({
                navigateOnSuccess: () => {
                    createNotificationSuccess(t('maintenanceGrids.updated'));
                    navigate(-1);
                },
                data: {
                    id: activeMaintenanceGridId,
                    // @ts-ignore
                    data: data,
                },
            })
            : // @ts-ignore
            createMaintenanceGrid(data);
    };

    useEffect(() => {
        Boolean(activeMaintenanceGridId) && refetchMaintenanceGridResponse();
        refetchEquipments();
    }, []);

    useEffect(() => {
        if (
            createMaintenanceGridError &&
            //@ts-ignore
            createMaintenanceGridError.response.data.error ===
            'Some modifications are already in the active maintenance grid'
        ) {
            const modificationNames =
                //@ts-ignore
                createMaintenanceGridError.response.data.message;

            createNotificationError(
                `${t('maintenanceGrids.gridsModel.viewGrid.activeGrid')}
        ${modificationNames.map((modification, index) =>
                    index !== modificationNames.length - 1
                        ? `${modification}, `
                        : modification,
                )}`,
            );
        }
    }, [ createMaintenanceGridError ]);

    useEffect(() => {
        if (
            maintenanceGridByModificationError &&
            //@ts-ignore
            maintenanceGridByModificationError.response.data.message === 'Not Found'
        ) {
            createNotificationError(
                'У данной модели нет активной сетки для дублирования',
            );
        }
    }, [ maintenanceGridByModificationError ]);

    useEffect(() => {
        if (
            updateMaintenanceGridError &&
            // @ts-ignore
            updateMaintenanceGridError.response.data.message[0] ===
            'works must contain not more than 50 elements'
        ) {
            createNotificationError('Работы должны содержать не более 50 элементов');
        } else if (
            updateMaintenanceGridError &&
            // @ts-ignore
            updateMaintenanceGridError.response.data.message[0] ===
            'materials must contain not more than 50 elements'
        ) {
            createNotificationError('ГСМ должны содержать не более 50 элементов');
        } else if (
            updateMaintenanceGridError &&
            // @ts-ignore
            updateMaintenanceGridError.response.data.message[0] ===
            'spares must contain not more than 50 elements'
        ) {
            createNotificationError(
                'Запчасти должны содержать не более 50 элементов',
            );
        } else if (updateMaintenanceGridError) {
            // @ts-ignore
            createNotificationError(updateMaintenanceGridError.response.data.message);
        }
    }, [ updateMaintenanceGridError ]);

    useEffect(() => {
        if (!creatingMaintenanceGridForm?.model) return;

        setCreatingModificationIds((prev) => [
            ...new Set([
                ...prev,
                ...(creatingMaintenanceGridForm?.model.equipments?.map(
                    (equipment) => equipment.id,
                ) || []),
            ]),
        ]);
    }, [ creatingMaintenanceGridForm?.model ]);

    useEffect(() => {
        setCreatingModificationIds(() => [
            ...(maintenanceGrid?.modifications?.map((equipment) => equipment.id) ||
                []),
        ]);
        if (
            creatingMaintenanceGridForm?.model.equipments[0] &&
            creatingMaintenanceGridForm?.model.equipments[0].fullName
        ) {
            setCreatingModificationIds((prev) => [
                ...new Set([
                    ...prev,
                    ...(creatingMaintenanceGridForm?.model.equipments?.map(
                        (equipment) => equipment.id,
                    ) || []),
                ]),
            ]);
        }

        let activeWorks =
            maintenanceGrid?.works?.map((work) => ({
                id: work?.work?.code,
                workId: work?.work?.id,
                workTypes: work?.work?.workTypes,
                name: `${work?.work?.name}`,
                quantity: work.amount,
                ...getAdaptMaintenanceNumbersForTable(
                    work.maintenanceNumbers.map((number) => ({
                        number: number.number,
                        workType: number.workType?.type,
                        workTypeId: number.workType?.id,
                    })),
                ),
            })) || [];

        let activeGsmList =
            maintenanceGrid?.materials?.map((material) => ({
                id: material?.material?.code,
                materialId: material?.material?.id,
                name: `${material?.material?.name}`,
                quantity: material.amount,

                ...getAdaptMaintenanceNumbersForTable(
                    // @ts-ignore
                    material.maintenanceGridMaterialNumbers,
                ),
            })) || [];

        const activeSparesList =
            maintenanceGrid?.spares?.map((spare) => ({
                id: spare?.spare?.code,
                spareCode: spare?.spare?.code,
                name: `${spare?.spare?.name}`,
                quantity: spare.amount,
                ...getAdaptMaintenanceNumbersForTable(
                    // @ts-ignore
                    spare.maintenanceGridSpareNumbers,
                ),
            })) || [];

        if (worksDefault?.data) {
            const activeWorksDefault =
                worksDefault?.data?.map((work) =>
                    createWorkTableRow({
                        name: { ...work, label: work.name, value: work.id },
                        quantity: 1,
                    }),
                ) || [];

            // @ts-ignore
            activeWorks = activeWorks.concat(activeWorksDefault);
        }

        if (gsmListDefault?.data) {
            const activeGsmListDefault =
                gsmListDefault?.data?.map((gsm) =>
                    createGsmTableRow({
                        name: { ...gsm, label: gsm.name, value: gsm.id },
                        quantity: 1,
                    }),
                ) || [];

            // @ts-ignore
            activeGsmList = activeGsmList.concat(activeGsmListDefault);
        }

        setIsStatusActive(Boolean(maintenanceGrid?.isActive));
        setWorksTableData(activeWorks);
        setGsmTableData(activeGsmList);
        setSparesTableData(activeSparesList);
    }, [ maintenanceGrid, gsmListDefault?.data, worksDefault?.data ]);

    const { state } = useLocation();

    useEffect(() => {
        if (state) {
            setCreatingModificationIdsLabel((prev) => {
                return `${state.model.model.name ? state.model.model.name : ''} ${
                    state.model.generation.name ? state.model.generation.name : ''
                } ${
                    state.model.generation.restylingName
                        ? state.model.generation.restylingName
                        : ''
                } `;
            });
        } else {
            setCreatingModificationIdsLabel((prev) => {
                return (
                    maintenanceGrid?.modifications?.map(
                        (equipment) =>
                            `${equipment.generation.model.name} ${
                                equipment.generation.name ? equipment.generation.name : ''
                            } ${
                                equipment.generation.restylingName === null
                                    ? ''
                                    : equipment.generation.restylingName
                            }`,
                    )[0] || ''
                );
            });
        }
    }, [ maintenanceGrid?.modifications ]);

    const handleSearchSpare = (value) => {
        setSearchSpare(value);
    };

    const handleSearchGrids = (value) => {
        setSearchGrids(value);
    };
    const handleSearchWorks = (value) => {
        setSearchWorks(value);
    };

    const handleCancelClick = () => {
        navigate(-1);
    };

    const handleExportMaintenanceGrid = () => {
        exportMaintenanceGrid(
            //@ts-ignore
            activeMaintenanceGridId,
            {
                onSuccess: (response) => {
                    handleFileLoad(response.data as Blob, 'maintenance-grid.xlsx');
                },
            },
        );
    };

    const {
        modalState: InfoModalState,
        openModal: InfoModalOpen,
        closeModal: InfoModalClose,
    } = useModal();

    return (
        <DashboardLayout
            title={title}
            toBack={-1}
            headerRight={
                <Space size={16} align='center'>
                    <Paragraph size={14}>
                        {t('maintenanceGrids.gridsModel.viewGrid.status') || ''}
                    </Paragraph>
                    <Switch
                        value={isStatusActive}
                        loading={isMaintenanceGridUpdating}
                        onChange={setIsStatusActive}
                    />
                </Space>
            }
        >
            <PermissionsAdapter
                resourceName={PAGE_PERMISSION_NAME}
                resourceAction='create'
                fallback={() => <span>No rights</span>}
            >
                <StyledMaintenanceGridsDetails>
                    <StyledContentTop>
                        <StyledInfoWrapper>
                            <Paragraph size={16} color='black'>
                                {t('maintenanceGrids.gridsModel.viewGrid.models') || ''}
                            </Paragraph>
                            <Button
                                onClick={InfoModalOpen}
                            >
                                Пояснения к сеткам ТО
                            </Button>
                        </StyledInfoWrapper>
                        <SelectField
                            label={creatingModificationIdsLabel}
                            value={idsSelectValues}
                            options={equipmentOptions}
                            mode='multiple'
                            maxTagCount='responsive'
                            error={errors.modifications}
                            onChange={handleModificationsChange}
                        />
                    </StyledContentTop>
                    <StyledContentTitle>
                        <StyledButtons size={8}>
                            <IconButton
                                icon='save'
                                isSquared={true}
                                variant='secondary'
                                color='gray'
                                onClick={handleExportMaintenanceGrid}
                            />
                            <IconButton
                                icon='share'
                                isSquared={true}
                                variant='secondary'
                                color='gray'
                                onClick={() => copyCurrentUrl()}
                            />
                        </StyledButtons>

                        <Paragraph size={18} color='black'>
                            {t('maintenanceGrids.gridsModel.viewGrid.grid') || ''}
                        </Paragraph>

                        {profileRoleType === 'kia' ? (
                            <StyledRoleButtons size={0}>
                                <StyledButton
                                    size='middle'
                                    theme='primary'
                                    color={isReadOnly ? 'gray' : 'brand'}
                                    onClick={() => setIsReadOnly((prev) => !prev)}
                                >
                                    {t('maintenanceGrids.gridsModel.viewGrid.manager') || ''}
                                </StyledButton>
                                <StyledButton
                                    size='middle'
                                    theme='primary'
                                    color={isReadOnly ? 'brand' : 'gray'}
                                    onClick={() => setIsReadOnly((prev) => !prev)}
                                >
                                    {t('maintenanceGrids.gridsModel.viewGrid.dealer') || ''}
                                </StyledButton>
                            </StyledRoleButtons>
                        ) : (
                            <></>
                        )}
                    </StyledContentTitle>

                    <StyledTableMaintenanceGrids
                        title={t('maintenanceGrids.gridsModel.viewGrid.works') || ''}
                        amountTitle={
                            t('maintenanceGrids.gridsModel.viewGrid.worksDescr') || ''
                        }
                        headers={worksTableHeaders}
                        data={worksTableData}
                        createRowButtonText={
                            t('maintenanceGrids.gridsModel.viewGrid.addNewWork') || ''
                        }
                        createRowSelectPlaceholder={
                            t('maintenanceGrids.gridsModel.viewGrid.addNewWorkDescr') || ''
                        }
                        createRowSelectOptions={worksOptions}
                        isReadOnly={isReadOnly}
                        error={errors.works}
                        onSearch={handleSearchWorks}
                        onRowCreate={handleWorkTableRowCreate}
                        onRowChange={handleWorkTableRowChange}
                        onRowDelete={handleWorkTableRowDelete}
                        isSearchShow={true}
                    />

                    <StyledTableMaintenanceGrids
                        title={t('maintenanceGrids.gridsModel.viewGrid.gsm') || ''}
                        amountTitle={
                            t('maintenanceGrids.gridsModel.viewGrid.gsmDescr') || ''
                        }
                        headers={gsmTableHeaders}
                        data={gsmTableData}
                        createRowButtonText={
                            t('maintenanceGrids.gridsModel.viewGrid.addNewGsm') || ''
                        }
                        createRowSelectPlaceholder={
                            t('maintenanceGrids.gridsModel.viewGrid.addNewDescr') || ''
                        }
                        createRowSelectOptions={gsmOptions}
                        isReadOnly={isReadOnly}
                        error={errors.gsm}
                        onSearch={handleSearchGrids}
                        onRowCreate={handleGsmTableRowCreate}
                        onRowChange={handleGsmTableRowChange}
                        onRowDelete={handleGsmTableRowDelete}
                        gsmCheckMark={true}
                        isSearchShow={true}
                    />

                    <StyledTableMaintenanceGrids
                        title={t('maintenanceGrids.gridsModel.viewGrid.details') || ''}
                        amountTitle={
                            t('maintenanceGrids.gridsModel.viewGrid.detailsDescr') || ''
                        }
                        headers={partsTableHeaders}
                        data={sparesTableData}
                        createRowButtonText={
                            t('maintenanceGrids.gridsModel.viewGrid.addNewPart') || ''
                        }
                        createRowSelectPlaceholder={
                            t('maintenanceGrids.gridsModel.viewGrid.code') || ''
                        }
                        createRowSelectOptions={sparesOptions}
                        isReadOnly={isReadOnly}
                        error={errors.spares}
                        onSearch={handleSearchSpare}
                        onRowCreate={handleSpareTableRowCreate}
                        onRowChange={handleSpareTableRowChange}
                        onRowDelete={handleSpareTableRowDelete}
                        gsmCheckMark={true}
                        isSearchShow={true}
                    />
                </StyledMaintenanceGridsDetails>

                <Space size={16}>
                    <Button color='brand' size='middle' onClick={handleCancelClick}>
                        {t('maintenanceGrids.gridsModel.viewGrid.cancel') || ''}
                    </Button>
                    <Button
                        color='brand'
                        size='middle'
                        theme='primary'
                        onClick={handleSubmitButtonClick}
                    >
                        {t('maintenanceGrids.gridsModel.viewGrid.save') || ''}
                    </Button>
                </Space>
            </PermissionsAdapter>
            <InfoModal
                isOpen={InfoModalState.isOpen}
                onCancel={InfoModalClose}
            />
        </DashboardLayout>
    );
};

export { MaintenanceGridsDetails };
