import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { HeaderArgType, IHeaderItem, SortOrder, TableSimple } from '@/packages/tableSimple/TableSimple';
import { StyledContent, StyledHeader, StyledTitle, StyledTitleWrapper } from '../index.styles';
import { Space } from 'antd';
import { IconButton } from '@/packages/icon-button/IconButton';
import { Switch } from '@/packages/formElements/inputs/switch';
import styled from 'styled-components';
import { TMaintenanceGridsRecord } from '@/helpers/api/maintenanceGrids/grids/types';
import { useNavigate } from 'react-router-dom';
import { ContextMaintenanceGrids, MAINTENANCE_GRIDS } from '@/hooks/contexts/useMaintenanceGrids';
import { Pagination } from '@/packages/pagination/Pagination';
import { getWordWithSuffix } from '@/helpers/utils';
import { DEFAULT_CREATE_MAINTENANCE_GRIDS_FORM_VALUES } from './hooks';
import { PermissionsAdapter } from '@/adapters/shared/PermissionsAdapter';
import { SelectField } from '@/packages/formElements/fields/SelectField';
import { useNotification } from '@/hooks/useNotification';
import { Tooltip } from '@/packages/Tooltip/Tooltip';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
import { GridsFilters } from '@/components/MaintenanceGrids/GridsTab/GridsFilters';
import { Paragraph } from '@/packages/paragraph/Paragraph';

interface IGridsTab {
    hasEdit: boolean;
    onEdit?: (row: IDataItem) => void;
    onDownload?: (row: IDataItem) => void;
    onShare?: (row: IDataItem) => void;
}

interface IDataItem extends TMaintenanceGridsRecord {
    model: TMaintenanceGridsRecord;
    status: {
        value: TMaintenanceGridsRecord['isActive'];
        isLoading?: boolean;
        onChange?: (value: boolean, row: IDataItem) => void;
    };
    actions?: {
        onDownload?: (row: IDataItem) => void;
        onShare?: (row: IDataItem) => void;
        onDetails?: (row: IDataItem) => void;
        onEdit?: (row: IDataItem) => void;
    };
}

const StyledSwitchWrapper = styled.div`
    margin-left: 16px;
`;

const HEADERS = (
  hasEdit: boolean,
): IHeaderItem<IDataItem>[] =>
    [
        {
            Header: t('maintenanceGrids.gridsModel.model') || '',
            accessor: 'model',
            isSearchable: true,
            isSortable: true,
            Cell: ({ value: model }) => {
                const [ showHiddenTags, setShowHiddenTags ] = useState(false);
                const { t } = useTranslation();

                const fullName = useMemo(() => {
                    return model.modifications.map((model) => model.fullName);
                }, [ model, model.modifications ]);

                const restylingName =
                    model.modifications[0]?.generation?.restylingName === null
                        ? ''
                        : model.modifications[0]?.generation?.restylingName;
                const label = `${model.modifications[0]?.generation?.model.name} ${model.modifications[0]?.generation?.name} ${restylingName} (${model.modifications[0]?.generation?.generationYearStart ?? ''}-${model.modifications[0]?.generation?.generationYearEnd ?? ''})`;

                const maxTagPlaceholder = (displayedValues) => {
                    const nameWithSuffix = getWordWithSuffix(displayedValues.length, [
                        t('maintenanceGrids.gridsModel.modification.1') || '',
                        t('maintenanceGrids.gridsModel.modification.2') || '',
                        t('maintenanceGrids.gridsModel.modification.3') || '',
                    ]);

                    return `${t('maintenanceGrids.gridsModel.more') || ''} ${
                        displayedValues.length
                    } ${nameWithSuffix}`;
                };

                return (
                    <div style={{ width: '450px' }}>
                        <SelectField
                            open={false}
                            value={fullName ?`${fullName} (${model?.modifications[0]?.vinEngineTransmission?.join(', ') ?? ''})` : null}
                            mode='multiple'
                            label={label}
                            maxTagCount={showHiddenTags ? undefined : 'responsive'}
                            removeIcon={() => null}
                            maxTagPlaceholder={maxTagPlaceholder}
                            onMouseEnter={() => setShowHiddenTags(true)}
                            onMouseLeave={() => setShowHiddenTags(false)}
                            showArrow={false}
                        />
                    </div>
                );
            },
        },
        hasEdit
            ? {
                Header: t('maintenanceGrids.gridsModel.status') || '',
                accessor: 'status',
                isSortable: true,

                width: '120px',
                minWidth: 120,
                maxWidth: 120,
                Cell: ({ value, row }) => {
                    return (
                        <StyledSwitchWrapper>
                            <Switch
                                value={value?.value}
                                loading={value?.isLoading}
                                onChange={(checked) => value?.onChange(checked, row.original)}
                            />
                        </StyledSwitchWrapper>
                    );
                },
            }
            : [],
        {
            Header: t('maintenanceGrids.gridsModel.actions') || '',
            accessor: 'actions',
            width: '160px',
            minWidth: 160,
            maxWidth: 160,
            isHidden: !hasEdit,
            Cell: ({ value, row }) => {
                console.log(value, row);return (
                    <Space align='center'>
                        <Tooltip
                            content={
                                <div>{t('maintenanceGrids.gridsModel.copyGrids') || ''}</div>
                            }
                        >
                            <div>
                                <IconButton
                                    icon='share'
                                    color='black'
                                    size='l'
                                    variant='tertiary'
                                    onClick={() => value?.onShare(row.original)}
                                />
                            </div>
                        </Tooltip>
                        <Tooltip
                            content={<div>{t('maintenanceGrids.gridsModel.info') || ''}</div>}
                        >
                            <div><a href={`/maintenance-grids/details/view/${row.original.id}`}>
                                <IconButton
                                    icon='eye-open'
                                    color='black'
                                    size='l'
                                    variant='tertiary'
                                    onClick={() => value?.onDetails(row.original)}
                                /></a>
                            </div>
                        </Tooltip>
                        <PermissionsAdapter
                            resourceAction='update'
                            resourceName='maintenance-grid'
                        >
                            <Tooltip
                                content={
                                    <div>{t('maintenanceGrids.gridsModel.edit') || ''}</div>
                                }
                            >
                                <div>
                                    <a href={`/maintenance-grids/details/edit/${row.original.id}`}>
                    <IconButton
                                        icon='edit'
                                        color='black'
                                        size='l'
                                        variant='tertiary'
                                        onClick={() => value?.onEdit(row.original)}
                                    /></a>
                                </div>
                            </Tooltip>
                        </PermissionsAdapter>
                    </Space>
                );
            },
        },
    ].flat();

const GridsTab: FC<IGridsTab> = (props) => {
    const { hasEdit, onEdit, onDownload, onShare } = props;
    const navigate = useNavigate();

    const { t } = useTranslation();

    const { createNotificationError } = useNotification();

    const {
        state: {
            maintenanceGrids,
            currentPage,
            isMaintenanceGridUpdating,
            updateMaintenanceGridError,
            debouncedSearchValueGrids,
            gridsFilters,
        },
        mutations: {
            setActiveMaintenanceGrid,
            setSearchValueGrids,
            setPage,
            setCreatingMaintenanceGridForm,
            setGridsFilters,
            setMaintenanceGridsSortParams,
        },
        actions: { updateMaintenanceGrid, resetUpdateMaintenanceGridQuery },
    } = useContext<MAINTENANCE_GRIDS>(ContextMaintenanceGrids);

    const handleEditButtonClick = (row: IDataItem) => {
        onEdit && onEdit(row);
    };

    const handleDownloadButtonClick = (row: IDataItem) => {
        onDownload && onDownload(row);
    };

    const handleDetailsButtonClick = (row: IDataItem) => {
        setActiveMaintenanceGrid(row);
        navigate(`/maintenance-grids/details/view/${row.id}`);
    };

    const handleShareButtonClick = (row: IDataItem) => {
        onShare && onShare(row);
    };

    const handleMaintenanceGridSearch = (key: string, value?: string) => {
        setSearchValueGrids(value || '');
    };

    const handleUpdateMaintenanceGridStatusButtonClick = (
        value: boolean,
        row: IDataItem,
    ) => {
        updateMaintenanceGrid({
            data: {
                id: row.id,
                data: {
                    isActive: value,
                },
            },
        });
    };
    const meta = maintenanceGrids?.meta;
    const paginationTotal = Number(`${meta?.pageCount}0`);
    const adaptMaintenanceGrids = useMemo(
        () =>
            maintenanceGrids?.data.map((item) => ({
                ...item,
                model: item,
                status: {
                    value: item.isActive,
                    isLoading: isMaintenanceGridUpdating,
                    onChange: handleUpdateMaintenanceGridStatusButtonClick,
                },
                actions: {
                    onDetails: handleDetailsButtonClick,
                    onEdit: handleEditButtonClick,
                    onDownload: handleDownloadButtonClick,
                    onShare: handleShareButtonClick,
                },
            })) || [],
        [ maintenanceGrids ],
    );

    const hasGrids = adaptMaintenanceGrids.length > 0;

    useEffect(() => {
        setCreatingMaintenanceGridForm(
            DEFAULT_CREATE_MAINTENANCE_GRIDS_FORM_VALUES,
        );
    }, []);

    useEffect(() => {
        if (
            updateMaintenanceGridError &&
            //@ts-ignore
            updateMaintenanceGridError.response.data.error ===
            'Some modifications are already in the active maintenance grid'
        ) {
            const modificationNames =
                //@ts-ignore
                updateMaintenanceGridError.response.data.message;

            createNotificationError(
                `${t('maintenanceGrids.gridsModel.createNotificationsError') || ''} 
        ${modificationNames.map((modification, index) =>
                    index !== modificationNames.length - 1
                        ? `${modification}, `
                        : modification,
                )}`,
            );
            resetUpdateMaintenanceGridQuery();
        }
    }, [ updateMaintenanceGridError ]);

    useEffect(() => {
        setPage(1);
    }, [ debouncedSearchValueGrids ]);

    const handleSort = (accessor: HeaderArgType, order: SortOrder) => {
        if (accessor.id === 'model') {
            setMaintenanceGridsSortParams((prev) => ({
                ...prev,
                sortAt: order ? order.toUpperCase() : null,
                sortBy: order ? 'modelName' : null,
            }));

        }
        if (accessor.id === 'status') {
            setMaintenanceGridsSortParams((prev) => ({
                ...prev,
                sortAt: order ? order.toUpperCase() : null,
                sortBy: order ? 'isActive' : null,
            }));
        }
    };

    return (
        <StyledContent>
            <>
                <StyledHeader>
                    <StyledTitleWrapper>
                        <StyledTitle>
                            <Paragraph size={16} color='black'>
                                {t('maintenanceGrids.gsmModel.grids') || ''}
                            </Paragraph>
                            <Paragraph size={10} color='gray3'>
                                {`${t('maintenanceGrids.worksModel.worksNum') || ''}
            ${maintenanceGrids?.meta?.itemCount} сеток`}
                            </Paragraph>
                        </StyledTitle>
                    </StyledTitleWrapper>
                </StyledHeader>
                <GridsFilters
                    setGridsFilters={setGridsFilters}
                    initValues={gridsFilters}
                    setPage={setPage}
                />
                <TableSimple
                    headers={HEADERS(!!hasEdit)}
                    data={adaptMaintenanceGrids}
                    headerParams={{
                        color: 'white',
                    }}
                    onSearch={handleMaintenanceGridSearch}
                    initialSearch={debouncedSearchValueGrids}
                    onSort={handleSort}
                />

                <Pagination
                    current={currentPage}
                    onChange={setPage}
                    pageSize={10}
                    total={paginationTotal ? paginationTotal : 1}
                />
            </>
        </StyledContent>
    );
};

export { GridsTab };
