import { t } from 'i18next';

enum EventTypes {
    CREATE = 'CREATE',
    UPDATE = 'UPDATE',
    DELETE = 'DELETE',
    ACTIVE = 'ACTIVE',
}

enum EventLogsEntities {
    MODELS = 'models',
    GENERATIONS = 'generations',
    MODIFICATION = 'modification',
    MAINTENANCE_GRID = 'maintenanceGrid',
    WORK = 'work',
    MATERIAL = 'material',
    USERS = 'users',
}

export const getLogMessage = (
    action: string | undefined,
    entity: string | undefined,
    data: any,
) => {
    let message = '';
    switch (action) {
        case EventTypes.CREATE:
            message = t('logs.create');
            break;
        case EventTypes.UPDATE:
            message = t('logs.update');
            break;
        case EventTypes.DELETE:
            message = t('logs.delete');
            break;
        case EventTypes.ACTIVE:
            message = t('logs.active');
            break;
    }
    switch (entity) {
        case EventLogsEntities.MODELS:
            if (action === EventTypes.DELETE) {
                message += `${t('logs.model')} "${data?.prevState?.name}" `;
            } else {
                message += `${t('logs.model')} "${data?.newState?.name}" `;
            }
            break;
        case EventLogsEntities.GENERATIONS:
            if (action === EventTypes.DELETE) {
                message += `${t('logs.generation')} "${data?.prevState?.name} ${
                    data?.prevState?.restylingName ? data?.prevState?.restylingName : ''
                }" ${t('logs.inModel')} "${data?.prevState?.model?.name}"`;
            } else {
                if (data.newState.updatedGeneration) {
                    message += `${t('logs.generation')} "${
                        data?.newState.updatedGeneration?.name
                    } ${
                        data?.newState.updatedGeneration?.restylingName
                            ? data?.newState.updatedGeneration?.restylingName
                            : ''
                    }" ${t('logs.inModel')} "${
                        data?.newState.updatedGeneration?.model?.name
                    }"`;
                } else {
                    message += `${t('logs.generation')} "${data?.newState?.name} ${
                        data?.newState?.restylingName ? data?.newState?.restylingName : ''
                    }" ${t('logs.inModel')} "${data?.newState?.model?.name}"`;
                }
            }

            break;
        case EventLogsEntities.MODIFICATION:
            if (action === EventTypes.DELETE) {
                message += `${t('logs.modification')} "${data?.prevState?.mpv} ${
                    data?.prevState?.carBodyType?.name
                } ${data?.prevState?.engineVolume?.volume} ${
                    data?.prevState?.engineType?.name
                } 
      ${data?.prevState?.fuelType?.name} ${
                    data?.prevState?.transmissionType?.name
                } " ${t('logs.inGeneration')} "${data?.prevState?.fullName} ${
                    data?.prevState?.generation?.restylingName
                        ? data?.prevState?.generation?.restylingName
                        : ''
                }", ${t("logs.inModel")} "${data?.prevState?.generation?.model?.name}"`;
            } else {
                message += `${t('logs.modification')} "${data?.newState?.mpv} ${
                    data?.newState?.carBodyType?.name
                } ${data?.newState?.engineVolume?.volume} ${
                    data?.newState?.engineType?.name
                } 
      ${data?.newState?.fuelType?.name} ${
                    data?.newState?.transmissionType?.name
                } " ${t('logs.inGeneration')} "${data?.newState?.generation?.name} ${
                    data?.newState?.generation?.restylingName
                        ? data?.newState?.generation?.restylingName
                        : ''
                }", ${t("logs.inModel")} "${data?.prevState?.generation?.model?.name}"`;
            }

            break;
        case EventLogsEntities.MAINTENANCE_GRID:
            const parsedNewState = JSON.parse(data?.newState);
            const parsedPrevState = JSON.parse(data?.prevState);
            console.log(parsedPrevState);
            message += `${t('logs.grids')} "${
                parsedNewState?.modifications[0]?.generation?.fullModelName
                    ? parsedNewState?.modifications[0]?.generation?.fullModelName
                    : parsedPrevState?.modifications[0]?.generation?.fullModelName
            }"`;
            break;
        case EventLogsEntities.WORK:
            console.log(data);
            if (action === EventTypes.DELETE) {
                message += `${t('logs.work')} "${data?.prevState?.name}"`;
            } else {
                message += `${t('logs.work')} "${data?.newState?.name}"`;
            }
            break;
        case EventLogsEntities.MATERIAL:
            if (action === EventTypes.DELETE) {
                message += `${t('logs.gsm')} "${data?.prevState?.name}"`;
            } else {
                message += `${t('logs.gsm')} "${data?.newState?.name}"`;
            }
            break;
        case EventLogsEntities.USERS:
            if (data?.prevState?.firstName !== data?.newState?.firstName) {
                message += `Имя "${data?.newState?.firstName}"`;
            }
            if (data?.prevState?.lastName !== data?.newState?.lastName) {
                message += `Фамилия "${data?.newState?.lastName}"`;
            }
            if (data?.prevState?.country !== data?.newState?.country) {
                message += `Страна "${data?.newState?.country}"`;
            }
            if (data?.prevState?.email._address !== data?.newState?.email._address) {
                message += `Email "${data?.newState?.email._address}"`;
            }
            if (data?.prevState?.phone !== data?.newState?.phone) {
                message += `Телефон "${data?.newState?.phone}"`;
            }
            if (data?.prevState?.role.id !== data?.newState?.role.id) {
                message += `Должность "${data?.newState?.role.name}"`;
            }
            if (data?.prevState?.isActive !== data?.newState?.isActive) {
                message += `Статус "${data?.newState?.isActive ? 'Активный' : 'Неактивный'}"`;
            }
            if (data?.prevState?.dealer.id !== data?.newState?.dealer.id) {
                message += `Дилерский центр "${data?.newState?.dealer.merchantName}"`;
            }
            break;
        default:
            break;
    }

    return message;
};
