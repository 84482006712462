import React, { useMemo } from "react";

import { CalendarTableColumnLeft } from "@/components/Calendar/CalendarTable/CalendarTableColumnLeft";
import { CalendarTableColumnRight } from "@/components/Calendar/CalendarTable/CalendarTableColumnRight";

import { StyledCalendarTable } from "./index.styles";

import { getDateDiff, getDatesArray, getDatesTree } from "@/helpers/date";

interface ICalendarTable {
  data: any[];
  endPeriodDate: Date;
  startPeriodDate: Date;
  scrollPage: any;
  setScrollPage: any;
  setScrollPrevPage: any;
  startPeriodDateFilters: Date;
  handleStatusNewModalCloseTable: () => void;
}

export const CalendarTable: React.FC<ICalendarTable> = (props) => {
  const {
    data,
    endPeriodDate,
    startPeriodDate,
    scrollPage,
    setScrollPage,
    setScrollPrevPage,
    startPeriodDateFilters,
    handleStatusNewModalCloseTable,
  } = props;

  const datesArray = getDatesArray(startPeriodDate, endPeriodDate);
  const datesTree = getDatesTree(datesArray);
  const calendarDatesDiff = getDateDiff(startPeriodDate, endPeriodDate);

  const table = useMemo(() => {
    return (
      <>
        <CalendarTableColumnLeft data={data} datesTree={datesTree} />

        <CalendarTableColumnRight
          data={data}
          datesTree={datesTree}
          datesArray={datesArray}
          calendarDatesDiff={calendarDatesDiff}
          scrollPage={scrollPage}
          setScrollPage={setScrollPage}
          setScrollPrevPage={setScrollPrevPage}
          startPeriodDateFilters={startPeriodDateFilters}
          handleStatusNewModalCloseTable={handleStatusNewModalCloseTable}
        />
      </>
    );
  }, [data, datesTree, datesArray]);

  return <StyledCalendarTable>{table}</StyledCalendarTable>;
};
