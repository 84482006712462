import React, { useEffect, useState } from "react";
import { useModal } from "@/hooks/useModal";
import { StyledTab, StyledTabType, } from "./index.styles";
import { TGetWarrantyByIdResponse } from "@/helpers/api/warranty-audit/types";
import { useGetProfile } from "@/helpers/api/profile/hooks";

import { DppoParams } from "../DppoParams";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { Violations } from "../Violations";
import { Withdrawal } from "../Withdrawal";
import { Plan } from "../Plan";
import { ChatModal, IMessageParams } from "@/components/Audits/ChatModal";
import { Statistics } from "../Statistics";
import { useTranslation } from "react-i18next";
import { PermissionsAdapter } from "@/adapters/shared/PermissionsAdapter";
import { TPermissionResourcesName } from "@/helpers/api/eventLogs/types";
import {
  NestedQuestionsSpares
} from "@/components/AuditWarranty/FillWarrantyStages/NestedQuestions/NestedQuestionsSpares";
import {
  NestedQuestionsWarranty
} from "@/components/AuditWarranty/FillWarrantyStages/NestedQuestions/NestedQuestionsWarranty";
import {
  AnswerProvider
} from "@/components/AuditWarranty/FillWarrantyStages/NestedQuestions/NestedQuestionsSpares/provider";
import { useReadAllAuditGaranteeMessage } from "@/helpers/api/audit-chat/hooks";

interface IFillWarrantySecondStage {
  warrantyData: TGetWarrantyByIdResponse;
  id: number;
  updateInfo: () => void;
}


export const FillWarrantySecondStage: React.FC<IFillWarrantySecondStage> = (
  props
) => {
  const [trigger, setTrigger] = useState(false);
  const { id, warrantyData, updateInfo } = props;
  const profile = useGetProfile();
  const isKiaProfileType = profile.data?.data.type === "kia";
  const isImplementerProfile =
    warrantyData.implementerId === profile.data?.data?.id;
  const { t } = useTranslation();

  const MOCK_TYPES: {
    label: string;
    value: string;
    idDealerHidden?: boolean;
    permissions: TPermissionResourcesName | TPermissionResourcesName[];
    isShow?: boolean;
  }[] = [
    {
      label: "warrantyAudits.list.dppo",
      value: "dppo",
      idDealerHidden: true,
      permissions: ["guarantee.audits-delegated-implementation"],
      isShow: !(warrantyData.type === 'factory' && isImplementerProfile)
    },
    {
      label: "warrantyAudits.list.warranty",
      value: "warranty",
      idDealerHidden: true,
      permissions: ["guarantee.audits-delegated-implementation"],
      isShow: !(warrantyData.type === 'dealer' && isImplementerProfile)
    },
    {
      label: "warrantyAudits.list.spares",
      value: "spares",
      idDealerHidden: true,
      permissions: ["guarantee.audits-delegated-implementation"],
      isShow: !(warrantyData.type === 'dealer' && isImplementerProfile)
    },
    {
      label: "warrantyAudits.list.violations",
      value: "violations",
      permissions: ["guarantee.violations"],
      isShow: true,
    },
    {
      label: "warrantyAudits.list.withdrawal",
      value: "withdrawal",
      permissions: ["guarantee.violations"],
      isShow: true,
    },
    {
      label: "warrantyAudits.list.plan",
      value: "plan",
      permissions: ["guarantee.task-managment"],
      isShow: true,
    },
    {
      label: "warrantyAudits.list.statistics",
      value: "statistics",
      permissions: ["guarantee.violations"],
      isShow: true,
    },
  ];
  const [selectedType, setSelectedType] = useState(
    isKiaProfileType ? MOCK_TYPES[0].value : MOCK_TYPES[3].value
  );

  useEffect(() => {
    const index =
      // @ts-ignore
      document.querySelector("#guarantee-tabs")?.childNodes[0]?.id;

    index && setSelectedType(MOCK_TYPES[+index].value);
  }, []);

  const [selectedParam, setSelectedParam] = useState<IMessageParams>();

  const handleOpenChat = (params: IMessageParams) => {
    setSelectedParam(params);
    ChatModalOpen();
  };

  const {
    modalState: ChatModalState,
    openModal: ChatModalOpen,
    closeModal: ChatModalClose,
  } = useModal();

  return (
    <>
      <StyledTabType id="guarantee-tabs">
        {MOCK_TYPES.map((el, index) =>
          (!(el.idDealerHidden && !isImplementerProfile) || isKiaProfileType) && el.isShow ? (
            <PermissionsAdapter
              resourceName={el?.permissions || []}
              resourceAction="read"
            >
              <StyledTab
                id={`${index}`}
                key={el.value}
                isActive={el.value === selectedType}
                onClick={() => {
                  setSelectedType(el.value);
                }}
              >
                <Paragraph size={12} weight={400}>
                  {t(el.label) || ""}
                </Paragraph>
              </StyledTab>
            </PermissionsAdapter>
          ) : (
            <></>
          )
        )}
      </StyledTabType>

      {
        {
          dppo: (
            <AnswerProvider>
              <DppoParams id={id} openChat={handleOpenChat} trigger={trigger}/>
            </AnswerProvider>
          ),
          warranty: (
            <AnswerProvider>
              <NestedQuestionsWarranty
                id={id}
                type={"Гарантийная документация"}
                isKiaProfileType={isKiaProfileType}
                openChat={handleOpenChat}
                trigger={trigger}
              />
            </AnswerProvider>

          ),
          spares: (
            <AnswerProvider>
              <NestedQuestionsSpares
                id={id}
                warrantyData={warrantyData}
                type={"Запасные части"}
                isKiaProfileType={isKiaProfileType}
                openChat={handleOpenChat}
                trigger={trigger}
              />
            </AnswerProvider>

          ),
          violations: (
            <Violations
              id={id}
              auditInfo={warrantyData}
              warrantyData={warrantyData}
            />
          ),
          withdrawal: <Withdrawal id={id} openChat={handleOpenChat}/>,
          plan: (
            <Plan
              id={id}
              auditInfo={warrantyData}
              openChat={handleOpenChat}
              updateInfo={updateInfo}
            />
          ),
          statistics: <Statistics id={id} auditInfo={warrantyData}/>,
        }[selectedType]
      }
      {selectedParam ? (
        <ChatModal
          params={selectedParam}
          readAllAuditMessage={useReadAllAuditGaranteeMessage}
          isOpen={ChatModalState.isOpen}
          onClose={() => {
            ChatModalClose();
            setTrigger((prev) => !prev);
          }}
          isWarranty={true}
        />
      ) : (
        <></>
      )}
    </>
  );
};
