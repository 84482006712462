import { Applications } from "@/pages/Applications";
import { ApplicationsCreate } from "@/pages/ApplicationsCreate";
import { ApplicationsEdit } from "@/pages/ApplicationsEdit";
import { AutoPark } from "@/pages/AutoPark";
import { AutoParkCreate } from "@/pages/AutoParkCreate";
import { AutoParkEdit } from "@/pages/AutoParkEdit";
import { Calendar } from "@/pages/Calendar";
import { ConfirmRegister } from "@/pages/ConfirmRegister";
import { DirectorRegistration } from "@/pages/DirectorRegistration";
import { Holdings } from "@/pages/Holdings";
import { KiaFlexCars } from "@/pages/KiaFlexCars";
import Login from "@/pages/Login";
import { MaintenanceGrids } from "@/pages/MaintenanceGrids";
import { MaintenanceGridsDetails } from "@/pages/MaintenanceGridsDetails";
import { MediaBase } from "@/pages/MediaBase";
import { Models } from "@/pages/Models";
import { Notifications } from "@/pages/Notifications";
import { Parts } from "@/pages/Parts";
import PasswordForgot from "@/pages/PasswordForgot";
import { Profile } from "@/pages/Profile";
import { ChangePassword } from "@/pages/Profile/ChangePassword";
import ChangeSettings from "@/pages/Profile/ChangeSettings";
import { Edit } from "@/pages/Profile/Edit";
import EmployeeManagement from "@/pages/Profile/EmployeeManagement/Index";
import { Register } from "@/pages/Registration";
import { RegistryCreate } from "@/pages/RegistryCreate";
import { RegistryDetail } from "@/pages/RegistryDetail";
import { RegistryList } from "@/pages/RegistryList";
import ResetPassword from "@/pages/ResetPassword";
import { Statistics } from "@/pages/Statistics";
import { TransportComplaints } from "@/pages/TransportComplaints";
import TransportComplaintsDetails from "@/pages/TransportComplaintsDetails";
import { VinDecoder } from "@/pages/VinDecoder";
import { AuditEdit } from "@/pages/AuditEdit";
import { AuditList } from "@/pages/AuditList";

import { AuditFill } from "@/pages/FillAudit";
import InformationalLetters from "@/pages/InformationalLetters";
import { InformationLettersCreate } from "@/pages/InformationLettersCreate";
import { InformationLettersInfo } from "@/pages/informationLettersInfo";
import { InformationLettersStatistic } from "@/pages/InformationLettersStatistic";
import { KiaFlexIdleCatalog } from "@/pages/KiaFlexIdleCatalog";
import { FlexSettings } from "@/pages/KiaFlexSettings";
import { KiaFlexStatus } from "@/pages/KiaFlexStatus";
import { MaintenanceGridsDetailsView } from "@/pages/MaintenanceGridsDetailsView";
import Materials from "@/pages/Materials";
import { MonitoringQuality } from "@/pages/MonitoringQuality";
import { MonitoringQualityCars } from "@/pages/MonitoringQualityCars";
import { MonitoringQualityCarsDealers } from "@/pages/MonitoringQualityCarsDealers";
import { MonitoringQualityCarsDealersInfo } from "@/pages/MonitoringQualityCarsDealersInfo";
import { MonitoringQualityCreate } from "@/pages/MonitoringQualityCreate";
import { MonitoringQualityCreateApplication } from "@/pages/MonitoringQualityCreateApplication";
import { MonitoringQualityDealers } from "@/pages/MonitoringQualityDealers";
import { MonitoringQualityEdit } from "@/pages/MonitoringQualityEdit";
import { MonitoringQualityEditApplication } from "@/pages/MonitoringQualityEditApplication";
import { MonitoringQualityForDealers } from "@/pages/MonitoringQualityForDealers";
import { MonitoringQualityInfo } from "@/pages/MonitoringQualityInfo";
import { News } from "@/pages/News";
import { NewsCreate } from "@/pages/NewsCreate";
import { NewsEdit } from "@/pages/NewsEdit";
import { NewsSingle } from "@/pages/NewsSingle";
import { BonusesReportsImport } from "@/pages/BonusesReportsImport";
import { BonusesReportsView } from "@/pages/BonusesReportsView";
import { BonusesCalculation } from "@/pages/BonusesCalculation";
import { BonusesPremium } from "@/pages/BonusesPremium";
import { AuditTotal } from "@/pages/AuditTotal";
import { AuditTasks } from "@/pages/AuditTasks";
import { AuditTemplateView } from "@/pages/AuditTemplateView";
import { AuditCreatePlan } from "@/pages/AuditCreatePlan";
import { AuditPlan } from "@/pages/AuditPlan";
import { AuditWarrantyTemplates } from "@/pages/AuditWarrantyTemplates";
import { WarrantyAuditList } from "@/pages/WarrantyAuditList";
import { AuditWarrantyTemplateView } from "@/pages/AuditWarrantyTemplateView";
import { AuditWarrantyEdit } from "@/pages/AuditWarrantyEdit";
import { WarrantyFill } from "@/pages/FillWarranty";
import { WarrantyPlan } from "@/pages/AuditWarrantyPlan";
import { AuditTemplates } from "@/pages/AuditTemplate";
import TransportComplaintsCreate from "@/pages/TransportComplaintsCreate";
import { NotificationsResult } from "@/pages/NotificationsResult";
import { BonusesPremiumView } from "@/pages/BonusesPremiumView";
import { PrivacyPolicy } from "@/pages/PrivacyPolicy";
import { TransportComplaintsEdit } from "@/pages/TransportComplaintsEdit";


import {
  ADMIN_ROUTE,
  APPLICATIONS_CREATE_ROUTE,
  APPLICATIONS_EDIT_ROUTE,
  APPLICATIONS_ROUTE,
  AUDIT_CREATE_PLAN,
  AUDIT_EDIT,
  AUDIT_FILL,
  AUDIT_LIST,
  AUDIT_PLAN,
  AUDIT_TASKS,
  AUDIT_TEMPLATES,
  AUDIT_TOTAL,
  AUDIT_VIEW,
  AUTOPARK_CREATE_ROUTE,
  AUTOPARK_EDIT_ROUTE,
  AUTOPARK_ROUTE,
  BONUSES_CALCULATION_ROUTE,
  BONUSES_DOCUMENT_VIEW_ROUTE,
  BONUSES_PREMIUM_ROUTE,
  BONUSES_REPORTS_IMPORT_ROUTE,
  BONUSES_REPORTS_VIEW_ROUTE,
  CALENDAR_ROUTE,
  CARD_DC_ID_ROUTE,
  CONFIRMREG_ROUTE,
  CREATE_USER_ROUTE,
  DIRECTOR_REGISTRATION_ROUTE,
  FLEX_CARS_ROUTE,
  FLEX_IDLE_CATALOG_ROUTE,
  FLEX_SETTINGS_ROUTE,
  FLEX_STATUS_ROUTE,
  HOLDINGS_ROUTE,
  INFORMATION_LETTERS_CREATE_ROUTE,
  INFORMATION_LETTERS_INFO_ROUTE,
  INFORMATION_LETTERS_ROUTE,
  INFORMATION_LETTERS_STATISTIC_ROUTE,
  LOGIN_ROUTE,
  MAINTENANCE_GRIDS_DETAILS_EDIT_ROUTE,
  MAINTENANCE_GRIDS_DETAILS_ROUTE,
  MAINTENANCE_GRIDS_DETAILS_VIEW_ROUTE,
  MAINTENANCE_GRIDS_ROUTE,
  MATERIALS_ROUTE,
  MEDIABASE_ROUTE,
  MODELS_ROUTE,
  MONITORING_QUALITY,
  MONITORING_QUALITY_CARS,
  MONITORING_QUALITY_CARS_DEALERS,
  MONITORING_QUALITY_CARS_DEALERS_INFO,
  MONITORING_QUALITY_CREATE_APPLICATION,
  MONITORING_QUALITY_CREATE_PROGRAM,
  MONITORING_QUALITY_DEALERS,
  MONITORING_QUALITY_EDIT,
  MONITORING_QUALITY_EDIT_APPLICATION,
  MONITORING_QUALITY_FOR_DEALERS,
  MONITORING_QUALITY_INFO,
  NEWS_CREATE_ROUTE,
  NEWS_EDIT_ROUTE,
  NEWS_ROUTE,
  NEWS_SINGLE_ROUTE,
  NOTIFICATIONS_RESULT_ROUTE,
  NOTIFICATIONS_ROUTE,
  PARTS_ROUTE,
  PASSFORGOT_ROUTE,
  PASSRES_ROUTE,
  PRIVACY_POLICY,
  PROFILE_CHANGESETTINGS_ROUTE,
  PROFILE_EDIT_ROUTE,
  PROFILE_EMPLMANAGMENT_ROUTE,
  PROFILE_PASSCHANGE_ROUTE,
  PROFILE_ROUTE,
  REGISTRATION_ROUTE,
  REGISTRY_CREATE_ROUTE,
  REGISTRY_ID_ROUTE,
  REGISTRY_ROUTE,
  STATISTICS_ROUTE,
  TRANSPORT_CLAIMS_CREATE_ROUTE,
  TRANSPORT_CLAIMS_DETAILS_ROUTE,
  TRANSPORT_CLAIMS_EDIT_ROUTE,
  TRANSPORT_CLAIMS_LINK_ROUTE,
  TRANSPORT_CLAIMS_ROUTE, USER_CARD_ROUTE,
  VIN_DECODER_ROUTE,
  WARRANTY_AUDIT_FILL,
  WARRANTY_AUDIT_LIST,
  WARRANTY_AUDIT_PLAN,
  WARRANTY_AUDIT_TEMPLATE_EDIT,
  WARRANTY_AUDIT_TEMPLATE_VIEW,
  WARRANTY_AUDIT_TEMPLATES,
  AUDIT_INFO,
} from "./routes";
import { TransportComplaintsLink } from "@/pages/TransportComplaintsLink";
import { AuditInfo } from "@/pages/AuditInfo";
import { Admin } from "@/pages/Admin";
import { UserCreate } from "@/pages/Admin/UserCreate";
import { UserCard } from "@/pages/Admin/UserCard";

export const PublicRoutes = [
  {
    path: LOGIN_ROUTE,
    Element: Login,
  },
  {
    path: PASSFORGOT_ROUTE,
    Element: PasswordForgot,
  },
  {
    path: DIRECTOR_REGISTRATION_ROUTE,
    Element: DirectorRegistration,
  },
  {
    path: REGISTRATION_ROUTE,
    Element: Register,
  },
  {
    path: CONFIRMREG_ROUTE,
    Element: ConfirmRegister,
  },
  {
    path: PASSRES_ROUTE,
    Element: ResetPassword,
  },
  {
    path: PRIVACY_POLICY,
    Element: PrivacyPolicy,
  },
];

export const PrivateRoutes = [
  {
    path: MODELS_ROUTE,
    Element: Models,
  },
  {
    path: ADMIN_ROUTE,
    Element: Admin,
  },
  {
    path: CREATE_USER_ROUTE,
    Element: UserCreate,
  },
  {
    path: USER_CARD_ROUTE,
    Element: UserCard,
  },
  {
    path: MAINTENANCE_GRIDS_ROUTE,
    Element: MaintenanceGrids,
  },
  {
    path: MAINTENANCE_GRIDS_DETAILS_ROUTE,
    Element: MaintenanceGridsDetails,
  },
  {
    path: MAINTENANCE_GRIDS_DETAILS_EDIT_ROUTE,
    Element: MaintenanceGridsDetails,
  },
  {
    path: MAINTENANCE_GRIDS_DETAILS_VIEW_ROUTE,
    Element: MaintenanceGridsDetailsView,
  },
  {
    path: VIN_DECODER_ROUTE,
    Element: VinDecoder,
  },
  {
    path: REGISTRY_ROUTE,
    Element: RegistryList,
  },
  {
    path: REGISTRY_CREATE_ROUTE,
    Element: RegistryCreate,
  },
  {
    path: REGISTRY_ID_ROUTE,
    Element: RegistryDetail,
  },
  {
    path: CARD_DC_ID_ROUTE,
    Element: RegistryDetail,
  },
  {
    path: BONUSES_REPORTS_IMPORT_ROUTE,
    Element: BonusesReportsImport,
  },
  {
    path: BONUSES_REPORTS_VIEW_ROUTE,
    Element: BonusesReportsView,
  },
  {
    path: BONUSES_CALCULATION_ROUTE,
    Element: BonusesCalculation,
  },
  {
    path: BONUSES_PREMIUM_ROUTE,
    Element: BonusesPremium,
  },
  {
    path: BONUSES_DOCUMENT_VIEW_ROUTE,
    Element: BonusesPremiumView,
  },
  {
    path: HOLDINGS_ROUTE,
    Element: Holdings,
  },
  {
    path: PROFILE_ROUTE,
    Element: Profile,
  },
  {
    path: PROFILE_EDIT_ROUTE,
    Element: Edit,
  },
  {
    path: PROFILE_PASSCHANGE_ROUTE,
    Element: ChangePassword,
  },
  {
    path: PROFILE_EMPLMANAGMENT_ROUTE,
    Element: EmployeeManagement,
  },
  {
    path: PROFILE_CHANGESETTINGS_ROUTE,
    Element: ChangeSettings,
  },
  {
    path: CALENDAR_ROUTE,
    Element: Calendar,
  },
  {
    path: MEDIABASE_ROUTE,
    Element: MediaBase,
  },
  {
    path: APPLICATIONS_ROUTE,
    Element: Applications,
  },
  {
    path: APPLICATIONS_CREATE_ROUTE,
    Element: ApplicationsCreate,
  },
  {
    path: APPLICATIONS_EDIT_ROUTE,
    Element: ApplicationsEdit,
  },
  {
    path: AUTOPARK_ROUTE,
    Element: AutoPark,
  },
  {
    path: AUTOPARK_CREATE_ROUTE,
    Element: AutoParkCreate,
  },
  {
    path: AUTOPARK_EDIT_ROUTE,
    Element: AutoParkEdit,
  },
  {
    path: PARTS_ROUTE,
    Element: Parts,
  },
  {
    path: STATISTICS_ROUTE,
    Element: Statistics,
  },
  {
    path: NOTIFICATIONS_ROUTE,
    Element: Notifications,
  },
  {
    path: NOTIFICATIONS_RESULT_ROUTE,
    Element: NotificationsResult,
  },
  {
    path: FLEX_CARS_ROUTE,
    Element: KiaFlexCars,
  },
  {
    path: FLEX_SETTINGS_ROUTE,
    Element: FlexSettings,
  },
  {
    path: FLEX_STATUS_ROUTE,
    Element: KiaFlexStatus,
  },
  {
    path: FLEX_IDLE_CATALOG_ROUTE,
    Element: KiaFlexIdleCatalog,
  },
  {
    path: NEWS_ROUTE,
    Element: News,
  },
  {
    path: NEWS_SINGLE_ROUTE,
    Element: NewsSingle,
  },
  {
    path: NEWS_EDIT_ROUTE,
    Element: NewsEdit,
  },
  {
    path: NEWS_CREATE_ROUTE,
    Element: NewsCreate,
  },
  {
    path: INFORMATION_LETTERS_ROUTE,
    Element: InformationalLetters,
  },
  {
    path: INFORMATION_LETTERS_CREATE_ROUTE,
    Element: InformationLettersCreate,
  },
  {
    path: INFORMATION_LETTERS_INFO_ROUTE,
    Element: InformationLettersInfo,
  },
  {
    path: INFORMATION_LETTERS_STATISTIC_ROUTE,
    Element: InformationLettersStatistic,
  },
  {
    path: MATERIALS_ROUTE,
    Element: Materials,
  },
  {
    path: TRANSPORT_CLAIMS_ROUTE,
    Element: TransportComplaints,
  },
  {
    path: TRANSPORT_CLAIMS_LINK_ROUTE,
    Element: TransportComplaintsLink,
  },
  {
    path: TRANSPORT_CLAIMS_DETAILS_ROUTE,
    Element: TransportComplaintsDetails,
  },
  {
    path: TRANSPORT_CLAIMS_EDIT_ROUTE,
    Element: TransportComplaintsEdit,
  },
  {
    path: TRANSPORT_CLAIMS_CREATE_ROUTE,
    Element: TransportComplaintsCreate,
  },
  {
    path: AUDIT_LIST,
    Element: AuditList,
  },
  {
    path: AUDIT_TOTAL,
    Element: AuditTotal,
  },
  {
    path: AUDIT_TEMPLATES,
    Element: AuditTemplates,
  },
  {
    path: AUDIT_EDIT,
    Element: AuditEdit,
  },
  {
    path: AUDIT_FILL,
    Element: AuditFill,
  },
  {
    path: AUDIT_INFO,
    Element: AuditInfo,
  },
  {
    path: AUDIT_TASKS,
    Element: AuditTasks,
  },
  {
    path: MONITORING_QUALITY,
    Element: MonitoringQuality,
  },
  {
    path: MONITORING_QUALITY_CREATE_PROGRAM,
    Element: MonitoringQualityCreate,
  },
  {
    path: MONITORING_QUALITY_CREATE_APPLICATION,
    Element: MonitoringQualityCreateApplication,
  },
  {
    path: MONITORING_QUALITY_EDIT_APPLICATION,
    Element: MonitoringQualityEditApplication,
  },
  {
    path: MONITORING_QUALITY_EDIT,
    Element: MonitoringQualityEdit,
  },
  {
    path: MONITORING_QUALITY_INFO,
    Element: MonitoringQualityInfo,
  },
  {
    path: MONITORING_QUALITY_DEALERS,
    Element: MonitoringQualityDealers,
  },
  {
    path: MONITORING_QUALITY_CARS_DEALERS_INFO,
    Element: MonitoringQualityCarsDealersInfo,
  },
  {
    path: MONITORING_QUALITY_CARS_DEALERS,
    Element: MonitoringQualityCarsDealers,
  },
  {
    path: MONITORING_QUALITY_CARS,
    Element: MonitoringQualityCars,
  },
  {
    path: MONITORING_QUALITY_FOR_DEALERS,
    Element: MonitoringQualityForDealers,
  },
  {
    path: AUDIT_VIEW,
    Element: AuditTemplateView,
  },
  {
    path: AUDIT_CREATE_PLAN,
    Element: AuditCreatePlan,
  },
  {
    path: FLEX_STATUS_ROUTE,
    Element: KiaFlexStatus,
  },
  {
    path: FLEX_SETTINGS_ROUTE,
    Element: FlexSettings,
  },
  {
    path: AUDIT_PLAN,
    Element: AuditPlan,
  },
  {
    path: WARRANTY_AUDIT_TEMPLATES,
    Element: AuditWarrantyTemplates,
  },
  {
    path: WARRANTY_AUDIT_LIST,
    Element: WarrantyAuditList,
  },
  {
    path: WARRANTY_AUDIT_FILL,
    Element: WarrantyFill,
  },
  {
    path: WARRANTY_AUDIT_TEMPLATE_VIEW,
    Element: AuditWarrantyTemplateView,
  },
  {
    path: WARRANTY_AUDIT_TEMPLATE_EDIT,
    Element: AuditWarrantyEdit,
  },
  {
    path: WARRANTY_AUDIT_PLAN,
    Element: WarrantyPlan,
  },
  {
    path: PRIVACY_POLICY,
    Element: PrivacyPolicy,
  },
];
