import React, { useEffect, useMemo, useState } from "react";

import { Space } from "antd";
import { Button } from "@/packages/button/Button";
import { DashboardLayout } from "@/layouts/DashboardLayout";
import { CalendarTable } from "@/components/Calendar/CalendarTable";
import { CalendarColors } from "@/components/Calendar/CalendarColors";
import { CalendarFilter } from "@/components/Calendar/CalendarFilter";
import { CalendarStatusModal } from "@/components/Calendar/CalendarStatusModal";

import {
  DEFAULT_CALENDAR_FILTER_FORM_VALUES,
  ICalendarFilterFormValues,
} from "@/components/Calendar/CalendarFilter/hooks";

import { useModal } from "@/hooks/useModal";
import {
  useCreateStatus,
  useDeleteStatus,
  useExportCalendar,
  useGetCalendar,
  useGetStatuses,
  usePatchStatus,
} from "@/helpers/api/testDrives/hooks";
import { CalendarStatus } from "@/components/Calendar/CalendarStatusModal/hooks";
import { IconButton } from "@/packages/icon-button/IconButton";
import { handleFileLoad } from "@/helpers/utils";

import { useTranslation } from "react-i18next";
import { useCopyCurrentUrl } from "@/hooks/useCopyCurrentUrl";
import { CalendarApplicationNewModal } from "@/components/Calendar/CalendarTable/CalendarTableColumnRight/CalendarApplication/CalendarApplicationNewModal";
import { ArrayParam, DateParam, useQueryParams } from "use-query-params";
import { Tooltip } from "@/packages/Tooltip/Tooltip";

export const Calendar: React.FC = () => {
  const [scrollPage, setScrollPage] = useState<number>(1);
  const [scrollPrevPage, setScrollPrevPage] = useState<number>(1);
  const [form, setForm] = useState({ ...DEFAULT_CALENDAR_FILTER_FORM_VALUES });
  const monthOffset = scrollPage * 12;
  const monthPrevOffset = scrollPrevPage * 6;
  const { t } = useTranslation();
  const date = new Date();

  const startPeriodDate = useMemo(
    () => new Date(date.setMonth(date.getMonth() - monthPrevOffset)),
    [scrollPrevPage]
  );
  const endPeriodDate = useMemo(
    () => new Date(date.setMonth(date.getMonth() + monthOffset)),
    [scrollPage]
  );

  const {
    modalState: CalendarStatusModalState,
    openModal: CalendarStatusOpenModal,
    closeModal: CalendarStatusCloseModal,
  } = useModal();

  const {
    modalState: CalendarApplicationNewModalState,
    openModal: CalendarApplicationNewOpenModal,
    closeModal: CalendarApplicationNewCloseModal,
  } = useModal();

  const [filterQueryParams, setFilterQueryParams] = useQueryParams({
    modelIds: ArrayParam,
    endPeriodDate: DateParam,
    startPeriodDate: DateParam,
    endPeriodDateForExport: DateParam,
    startPeriodDateForExport: DateParam,
  });

  const filterParams: ICalendarFilterFormValues = {
    modelIds: filterQueryParams?.modelIds as string[],
    endPeriodDate: filterQueryParams.endPeriodDate as Date | null,
    startPeriodDate: filterQueryParams.startPeriodDate as Date | null,
    endPeriodDateForExport:
      filterQueryParams.endPeriodDateForExport as Date | null,
    startPeriodDateForExport:
      filterQueryParams.startPeriodDateForExport as Date | null,
  };

  const { data: calendarData, refetch: refetchCalendar } = useGetCalendar({
    "sort[modelsIds]": form.modelIds,
    "filter[modelIds]": form.modelIds,
    "filter[startEvent]": form.startPeriodDateForExport
      ? form.startPeriodDateForExport
      : startPeriodDate,
    "filter[endEvent]": form.endPeriodDateForExport
      ? form.endPeriodDateForExport
      : endPeriodDate,
  });

  const { data: statusesData, refetch: refetchStatuses } = useGetStatuses({
    withoutRejection: true,
  });

  const { mutateAsync: createStatusAsync } = useCreateStatus();
  const { mutateAsync: patchStatusAsync } = usePatchStatus();
  const { mutateAsync: deleteStatusAsync } = useDeleteStatus();

  const statuses = statusesData?.data.data;

  const { mutateAsync: exportCalendar } = useExportCalendar();

  const handleFilterChange = (data: ICalendarFilterFormValues) => {
    setForm(data);
    setFilterQueryParams(data);
  };

  const handleStatusButtonClick = () => {
    CalendarStatusOpenModal();
  };

  const handleStatusModalClose = () => {
    CalendarStatusCloseModal();
    refetchStatuses();
    refetchCalendar();
  };

  const handleStatusNewModalButtonClick = () => {
    CalendarApplicationNewOpenModal();
  };

  const handleStatusNewModalClose = () => {
    CalendarApplicationNewCloseModal();
    refetchStatuses();
    refetchCalendar();
  };
  const handleStatusNewModalCloseTable = () => {
    refetchStatuses();
    refetchCalendar();
  };

  const copyCurrentUrl = useCopyCurrentUrl({
    successMessage: t("bonuses.reportsView.successMessage") || "",
  });

  const handleStatusFormSubmit = async (data) => {
    const statusesFromForm = data.status;
    const statusFromFormIds = statusesFromForm.map((status) => status.id);
    const statusFromResponseIds = statuses.map((status) => status.id);

    let statusesForCreate = [] as CalendarStatus[];
    let statusesForPatch = [] as CalendarStatus[];
    let statusesForDelete = [] as CalendarStatus[];

    statusesFromForm.forEach((status) => {
      if (statusFromResponseIds.includes(status.id)) {
        statusesForPatch.push(status);
      } else {
        statusesForCreate.push(status);
      }
    });

    statuses.forEach((status) => {
      if (!statusFromFormIds.includes(status.id)) {
        statusesForDelete.push(status);
      }
    });

    statusesForCreate.forEach((status) => {
      createStatusAsync({
        title: status.title,
        color: status.color,
        active: true,
        appointment: status.appointment,
      });
    });

    statusesForPatch.forEach((status) => {
      patchStatusAsync({
        id: status.id,
        patch: {
          title: status.title,
          color: status.color,
        },
      });
    });

    statusesForDelete.forEach((status) => {
      deleteStatusAsync({
        id: status.id,
      });
    });

    CalendarStatusCloseModal();
  };

  useEffect(() => {
    refetchCalendar();
  }, []);

  const handleExportCalendar = () => {
    exportCalendar(
      {
        "filter[modelId]": form.modelIds,
        "filter[startEvent]": startPeriodDate,
        "filter[endEvent]": endPeriodDate,
      },
      {
        onSuccess: (response) => {
          handleFileLoad(response.data as Blob, "requests.xlsx");
        },
      }
    );
  };

  return (
    <DashboardLayout
      title={t("park.subTitle_1") || ""}
      headerRight={
        <Space>
          <Tooltip content={t("common.save") || ""} placement="bottom">
            <IconButton
              icon="save"
              isSquared
              variant="primary"
              color="white"
              size="m"
              onClick={handleExportCalendar}
            />
          </Tooltip>
          <Tooltip content={t("common.share") || ""} placement="bottom">
            <IconButton
              icon="share"
              isSquared
              variant="primary"
              color="white"
              size="m"
              onClick={() => copyCurrentUrl()}
            />
          </Tooltip>
          <Button
            color="brand"
            size="middle"
            theme="primary"
            onClick={handleStatusNewModalButtonClick}
          >
            {t("park.addNew") || ""}
          </Button>
        </Space>
      }
    >
      <CalendarFilter
        onFiltersChange={handleFilterChange}
        initValues={filterParams}
      />
      <CalendarColors
        data={statusesData?.data.data || []}
        onButtonEditClick={handleStatusButtonClick}
      />

      {endPeriodDate && startPeriodDate ? (
        <CalendarTable
          data={calendarData?.data.data || []}
          endPeriodDate={endPeriodDate}
          startPeriodDate={startPeriodDate}
          startPeriodDateFilters={
            form.startPeriodDateForExport
              ? form.startPeriodDateForExport
              : startPeriodDate
          }
          scrollPage={scrollPage}
          setScrollPage={setScrollPage}
          setScrollPrevPage={setScrollPrevPage}
          handleStatusNewModalCloseTable={handleStatusNewModalCloseTable}
        />
      ) : (
        <></>
      )}

      <CalendarStatusModal
        {...CalendarStatusModalState}
        isOpen={CalendarStatusModalState.isOpen}
        defaultValues={
          statusesData?.data.data?.map((status) => ({
            id: status.id,
            title: status.title,
            color: status.color,
            lockActive: status.lockActive,
            appointment: status.appointment,
          })) || []
        }
        onSubmit={handleStatusFormSubmit}
        onCancel={handleStatusModalClose}
      />

      {CalendarApplicationNewModalState.isOpen ? (
        <CalendarApplicationNewModal
          {...CalendarApplicationNewModalState}
          isOpen={CalendarApplicationNewModalState.isOpen}
          closeModal={handleStatusNewModalClose}
          startEventForNew={
            form.startPeriodDateForExport
              ? form.startPeriodDateForExport
              : startPeriodDate
          }
          vehicleIdForNew={null}
        />
      ) : (
        <></>
      )}
    </DashboardLayout>
  );
};
