import React, { useState } from "react";
import { AuditWarrantyTableAdapter } from "@/components/AuditWarranty/AuditWarrantyTableAdapter";
import { usePagination } from "@/hooks/usePagination";
import { useNavigate } from "react-router-dom";
import { DashboardLayout } from "@/layouts/DashboardLayout";
import {
  getDefaultAuditWarrantyTemplatesTableHeaders,
  MOCK_AUDIT_FILTERS,
} from "./helpers";
import {
  StyledButtons,
  StyledContentTitle,
  StyledSelectField,
  StyledInputField,
  StyledButtonsTop,
  StyledFilters,
} from "./index.styles";
import { Button } from "@/packages/button/Button";
import { IconButton } from "@/packages/icon-button/IconButton";
import { useNotification } from "@/hooks/useNotification";
import { useCopyCurrentUrl } from "@/hooks/useCopyCurrentUrl";
import {
  useCreateWarrantyTemplate,
  useDeleteWarrantyAuditTemplate,
  useGetAuditTemplateExample,
  useGetWarrantyAuditTemplateList,
  usePatchWarrantyAuditTemplate,
} from "@/helpers/api/warranty-audit/templates/hooks";
import { useRequestParams } from "@/hooks/useRequestParams";
import {
  UploadWarrantyAuditTemplateModal
} from "@/components/AuditWarranty/Templates/UploadWarrantyAuditTemplateModal";
import { useModal } from "@/hooks/useModal";
import { requestParamsMapper } from "@/helpers/utils";
import { WARRANTY_AUDIT_TEMPLATE_EDIT } from "@/constants/routes";
import { HeaderArgType, SortOrder } from "@/packages/tableSimple/TableSimple";
import { useTranslation } from "react-i18next";
import { useDebounce } from "use-debounce";
import { useUserInfoModel } from "@/components/UserInfoModal/provider";

const handleCsvLoad = async (data: Blob) => {
  const link = document.createElement("a");
  link.href = URL.createObjectURL(data);
  link.download = "audit_template.xlsx";
  link.click();
};

export const AuditWarrantyTemplates: React.FC = () => {
  const { createNotificationError } = useNotification();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [requestParams, setRequestParams] = useRequestParams({
    title: { value: "", type: "filter" },
    active: { value: "", type: "filter" },
    sortBy: {
      value: "createdAt",
      type: "sort",
    },
    sortAt: {
      value: "DESC",
      type: "sort",
    },
  });

  const { setPage, paginationParams } = usePagination();
  const [title, setTitle] = useState('');
  const [debouncedTitle] = useDebounce(title, 500);

  const copyCurrentUrl = useCopyCurrentUrl({
    successMessage: t("dealerMaterials.materials.successMessage") || "",
  });
  const { mutate: getTemplate, isLoading: isFileDownloading } =
    useGetAuditTemplateExample();
  const { mutate: createWarrantyTemplate } =
    useCreateWarrantyTemplate();
  const {
    modalState,
    openModal: openUploadModal,
    closeModal: closeUploadModal,
  } = useModal();

  const { mutate: patchWarrantyTemplate } = usePatchWarrantyAuditTemplate();
  const { mutate: deleteWarrantyTemplate } = useDeleteWarrantyAuditTemplate();

  const {
    data: templateList,
    isLoading: isListLoading,
    refetch: updateTemplateList,
  } = useGetWarrantyAuditTemplateList({
    ...requestParamsMapper(requestParams),
    "page[limit]": paginationParams.limit,
    "page[page]": paginationParams.page,
    "filter[title]": debouncedTitle,
  });

  const handleCreateAuditTemplate = () => {
    createWarrantyTemplate({
      onSuccess: (response) => {
        handleOpenEdit(response.id);
      },
      onError: (error) => {
        createNotificationError(error.response?.data?.message || "");
      },
    });
    updateTemplateList();
  };

  const handleSubmitUploadTemplate = () => {
    updateTemplateList();
    closeUploadModal();
  };

  const handleUpdateTemplate = (patch) => {
    patchWarrantyTemplate({
      patch,
      onSuccess: updateTemplateList,
      onError: (error) =>
        createNotificationError(error.response?.data.message || error.message),
    });
  };

  const handleDownloadFile = () => {
    getTemplate({
      onSuccess: (response) => {
        handleCsvLoad(response.data);
      },
      onError: (error) =>
        createNotificationError(error.response?.data.message || error.message),
    });
  };

  const handleDeleteTemplate = (id: number) => {
    deleteWarrantyTemplate({
      id,
      onSuccess: updateTemplateList,
      onError: (error) =>
        createNotificationError(error.response?.data.message || error.message),
    });
  };

  const { dispatch } = useUserInfoModel();

  const headers = getDefaultAuditWarrantyTemplatesTableHeaders({
    onAuditUpdate: handleUpdateTemplate,
    onDeleteClick: handleDeleteTemplate,
    onEditClick: (id) => {
      navigate(`/warranty-audit-template/edit/${id}`);
    },
    onViewClick: (id) => {
      navigate(`/warranty-audit-template/${id}`);
    },
    openUserModal: dispatch,
  });

  const handleOpenEdit = (id: number) => {
    navigate(WARRANTY_AUDIT_TEMPLATE_EDIT.replace(":id", id.toString()));
  };

  const handleSort = (accessor: HeaderArgType, order: SortOrder) => {
    setRequestParams((prev) => ({
      ...prev,
      sortAt: { type: "sort", value: order ? order.toUpperCase() : null },
      sortBy: { type: "sort", value: order ? accessor.id : null },
    }));
  };

  return (
    <>
      <DashboardLayout
        hasMobile={true}
        title={t("warrantyAudits.templates.title") || ""}
        headerRight={
          <StyledButtonsTop>
            <Button
              color="brand"
              theme="secondary"
              size="small"
              onClick={handleDownloadFile}
              isLoading={isFileDownloading}
            >
              {t("audits.templates.download") || ""}
            </Button>
            <Button
              color="brand"
              theme="secondary"
              size="small"
              isLoading={false}
              onClick={openUploadModal}
            >
              {t("audits.templates.download_2") || ""}
            </Button>
            <Button
              color="brand"
              theme="primary"
              size="small"
              isLoading={false}
              onClick={handleCreateAuditTemplate}
            >
              {t("audits.templates.create") || ""}
            </Button>
          </StyledButtonsTop>
        }
      >
        <StyledContentTitle>
          <StyledFilters>
            <StyledInputField
              label={t("common.name") || ""}
              placeholder={t("common.enterName") || ""}
              onChange={(value) => setTitle(value)}
            />
            <StyledSelectField
              label={t("audits.templates.status") || ""}
              placeholder={t("audits.list.all") || ""}
              options={MOCK_AUDIT_FILTERS}
              onChange={(_, option) => {
                if (!Array.isArray(option)) {
                  setRequestParams((prev) => ({
                    ...prev,
                    active: {
                      value: !option.value ? "" : option.value === "true",
                      type: "filter",
                    },
                  }));
                }
              }}
            />
          </StyledFilters>
          <StyledButtons size={8}>
            <IconButton
              icon="share"
              isSquared={true}
              variant="primary"
              color="white"
              onClick={() => copyCurrentUrl()}
            />
          </StyledButtons>
        </StyledContentTitle>
        <AuditWarrantyTableAdapter
          setPage={setPage}
          isLoading={isListLoading}
          paginationParams={{
            ...paginationParams,
            limit: templateList?.data.meta?.pageCount || 0,
          }}
          // @ts-ignore
          headers={headers || []}
          data={templateList?.data.data || []}
          onSort={handleSort}
        />
      </DashboardLayout>
      <UploadWarrantyAuditTemplateModal
        isOpen={modalState.isOpen}
        onSubmit={handleSubmitUploadTemplate}
        onCancel={closeUploadModal}
      />
    </>
  );
};
