import React, { useContext, useState } from "react";
import { ContextProfile } from "@/hooks/contexts/useProfile";
import { DashboardLayout } from "@/layouts/DashboardLayout";
import { useEditWarrantyTaskStatus, useGetWarrantyAuditTasks, } from "@/helpers/api/warranty-audit/plan/hooks";
import { useRequestParams } from "@/hooks/useRequestParams";
import {
  TGetWarrantyPlanTasksKiaRequest,
  TWarrantyAuditTaskDto,
  TWarrantyEditStatusRequest,
} from "@/helpers/api/warranty-audit/plan/types";
import { requestParamsMapper } from "@/helpers/utils";
import { usePagination } from "@/hooks/usePagination";
import { AuditWarrantyTableAdapter } from "@/components/AuditWarranty/AuditWarrantyTableAdapter";
import { getDefaultWarrantyPlanTasksHeaders, warrantyChatBtn, } from "@/pages/AuditWarrantyPlan/utils";
import { useNotification } from "@/hooks/useNotification";
import { useModal } from "@/hooks/useModal";
import { PermissionsAdapter } from "@/adapters/shared/PermissionsAdapter";
import { ChatModal } from "@/components/Audits/ChatModal";
import WarrantyAuditPlanFilter from "./WarrantyAuditPlanFilter";
import { StyledContentTitle, StyledSelectField, } from "./WarrantyAuditPlanFilter/index.styles";
import { useTranslation } from "react-i18next";
import { MOCK_TASK_STATUS } from "@/components/Audits/Tasks/TasksListTable/helpers";
import { useReadAllAuditGaranteeMessage } from "@/helpers/api/audit-chat/hooks";
import { useUserInfoModel } from "@/components/UserInfoModal/provider";

const WarrantyPlan: React.FC = () => {
  const { t } = useTranslation();
  const { paginationParams, setPage } = usePagination();
  const {
    state: { profile },
  } = useContext(ContextProfile);
  const isDealerKia = profile?.type === "kia";

  const [selectedTask, setSelectedTask] =
    useState<TWarrantyAuditTaskDto | null>(null);
  const [sortParams, _] = useState({});
  const { createNotificationSuccess, createNotificationError } =
    useNotification();

  const [requestParams, setRequestParams] =
    useRequestParams<TGetWarrantyPlanTasksKiaRequest>({
      // "filter[auditId]": auditId,
    });
  const {
    data: planData,
    isLoading: isLoadingData,
    refetch: updateKia,
  } = useGetWarrantyAuditTasks(
    {
      ...sortParams,
      ...requestParamsMapper(requestParams),
      "page[page]": paginationParams.page,
      "page[limit]": paginationParams.limit,
    },
    isDealerKia
  );

  const { mutateAsync: setTaskStatus } =
    useEditWarrantyTaskStatus();

  const {
    modalState: ChatModalState,
    openModal: ChatModalOpen,
    closeModal: ChatModalClose,
  } = useModal();

  const handleOpenChat = (data) => {
    setSelectedTask(data);
    ChatModalOpen();
  };

  const handleSetTaskStatus = (
    id: number,
    patch: TWarrantyEditStatusRequest
  ) => {
    setTaskStatus({
      taskId: id,
      data: patch,
      isDealerKia,
      onSuccess: () => {
        createNotificationSuccess("Статус задачи обновлен");
        ChatModalClose();
        updateKia();
      },
      onError: (error) => {
        createNotificationError(
          error.response?.data?.message || error?.message
        );
      },
    });
  };

  const { dispatch } = useUserInfoModel();


  const headers = getDefaultWarrantyPlanTasksHeaders({
    isKia: isDealerKia,
    onSetTaskStatus: handleSetTaskStatus,
    onMessageClick: handleOpenChat,
    openUserModal: dispatch,
  });

  const handleCloseChat = () => {
    ChatModalClose();
    updateKia();
  };

  return (
    <>
      <DashboardLayout
        hasMobile={true}
        title={t("warrantyAudits.tasks.description") || ""}
      >
        <PermissionsAdapter
          resourceName="guarantee.task-implementation"
          resourceAction="read"
        >
          <StyledContentTitle>
            <StyledSelectField>
              <WarrantyAuditPlanFilter
                onFilterChange={(data: TGetWarrantyPlanTasksKiaRequest) => {
                  setRequestParams((prev) => ({ ...prev, ...data }));
                }}
                filterFields={["status", "auditor"]}
                statusOptions={[
                  {
                    label: "audits.list.all",
                    value: "",
                  },
                  ...MOCK_TASK_STATUS,
                ]}
                specialFields={["responsible"]}
              />
            </StyledSelectField>
          </StyledContentTitle>

          <AuditWarrantyTableAdapter
            isCutVerticalPadding
            isDealerKia={profile?.type === "kia"}
            data={planData?.data?.data || []}
            isLoading={isLoadingData}
            paginationParams={{
              ...paginationParams,
              limit: planData?.data.meta ? planData?.data.meta.pageCount : 1,
            }}
            setPage={setPage}
            // @ts-ignore
            headers={headers || []}
            defaultColumn={{
              minWidth: 150,
              width: 220,
              maxWidth: 300,
            }}
          />

          {selectedTask ? (
            <ChatModal
              readAllAuditMessage={useReadAllAuditGaranteeMessage}
              params={{ taskId: selectedTask.id }}
              isOpen={ChatModalState.isOpen}
              onClose={handleCloseChat}
              btnCallback={warrantyChatBtn({
                isDealerKia,
                t,
                selectedTask,
                handleEditStatus: handleSetTaskStatus,
              })}
              isWarranty={true}
            />
          ) : (
            <></>
          )}
        </PermissionsAdapter>
      </DashboardLayout>
    </>
  );
};

export { WarrantyPlan };
