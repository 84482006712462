import { PermissionsAdapter } from "@/adapters/shared/PermissionsAdapter";
import { InformationalLettersTable } from "@/components/informationalLetters";
import { StyledCollapse, StyledPanel } from "@/components/informationalLetters/index.styles";
import { Icon } from "@/packages/icon/Icon";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { Space } from "antd";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Row = ({
               section,
               openInformationLettersEditModalState,
               onDeleteIPHandler,
               children,
               setActiveMaterialKeys,
               activeMaterialKeys,
               searchValue,
             }) => {
  const { t } = useTranslation();

  const handlePanelChange = (key) => {
    setActiveMaterialKeys((prevActiveKeys) => {
      if (!prevActiveKeys) {
        return [key]
      }
      if (prevActiveKeys?.includes(key)) {
        return prevActiveKeys.filter((k) => k !== key);
      } else {
        return [...prevActiveKeys, key];
      }
    });
  };

  const getActiveKey = () => {
    if (!activeMaterialKeys) {
      return;
    }
    const index = activeMaterialKeys.indexOf(section.id)
    if (index > -1) {
      return activeMaterialKeys[index];
    }
  };

  return (
    <StyledCollapse
      accordion
      expandIconPosition="end"
      bordered={false}
      className="site-collapse-custom-collapse"
      defaultActiveKey={getActiveKey()}
      onChange={handlePanelChange}
    >
      <StyledPanel header={`${section.level} ${section.name}`} key={section.id}>
        <PermissionsAdapter
          resourceName="information-letters"
          resourceAction="create"
        >
          <Link to={`/information-letters/${section.id}/create`}>
            <Space align="center">
              <Icon name="plus" size={12}/>
              <Paragraph size={12} color="black">
                {t("dealerMaterials.letters.addIP") || ""}
              </Paragraph>
            </Space>
          </Link>
        </PermissionsAdapter>
        <InformationalLettersTable
          onOpenEditModal={(record) =>
            openInformationLettersEditModalState({ record })
          }
          onDeleteIp={onDeleteIPHandler}
          parrentId={section.id}
          searchValue={searchValue}
        />
        {children ? children : <></>}
      </StyledPanel>
    </StyledCollapse>
  );
};

export default Row;
