import { TGetTransportClaimsByIdResponse } from "@/helpers/api/transportComplaints/types";
import { formatDate } from "@/helpers/formatDate";
import React, { memo } from "react";
import { FormProvider } from "react-hook-form";
import { TrasnportComplaintsFields } from "../TransportComplaintsFields";
import { ITransportComDetailFormValues, useDetailTCForm } from "./hooks";
import {
  StyledBtnsWrapper,
  StyledButton,
  StyledIconButton,
  StyledMainInfoCard,
  StyledMainTitle,
  StyledMainWrapperCard,
} from "./index.styles";
import { Tooltip } from "@/packages/Tooltip/Tooltip";
import { useTranslation } from "react-i18next";
import { useCopyCurrentUrl } from "@/hooks/useCopyCurrentUrl";
import { Button } from "@/packages/button/Button";
import { To, useNavigate, useParams } from "react-router-dom";
import { Space } from "antd";
import { useGetProfile } from "@/helpers/api/profile/hooks";

interface TransportComplaintsDetailProps {
  detailRecord: TGetTransportClaimsByIdResponse;
  isReadOnly?: boolean;
  onSubmit?: (editData: ITransportComDetailFormValues) => Promise<void>;
}

const _TransportComplaintsDetail: React.FC<TransportComplaintsDetailProps> = (
  props
) => {
  const { detailRecord, isReadOnly, onSubmit } = props;

  const navigate = useNavigate();
  const { id } = useParams();

  const { t } = useTranslation();

  const copyCurrentUrl = useCopyCurrentUrl({
    successMessage: t("bonuses.reportsView.successMessage") || "",
  });

  const handleCancelClick = () => {
    navigate(id?.includes('&goBack') ? '/transport-claims' as To : -1 as To);
  };

  const profile = useGetProfile();
  const isDealerProfileType = profile?.data?.data.type === "dealer";
  const isDistributorProfileType = profile?.data?.data?.role?.slug === "cis-distributor";
  const isElladaProfile = profile?.data?.data?.role?.slug === "ellada";

  //@ts-ignore
  const { form, submit } = useDetailTCForm({ detailRecord, onSubmit });

  return (
    <>
      <FormProvider {...form}>
        <StyledMainTitle>
          <StyledMainWrapperCard>
            <StyledMainInfoCard>
              <div>VIN автомобиля:</div>
              <div> {detailRecord.vin}</div>
            </StyledMainInfoCard>
            <StyledMainInfoCard>
              <div>Код случая:</div>
              <div>{detailRecord.codeCase}</div>
            </StyledMainInfoCard>
            <StyledMainInfoCard>
              <div>Дата приемки автомобиля:</div>
              <div>
                {formatDate(detailRecord.dateAcceptence || "", "dateOnly")}
              </div>
            </StyledMainInfoCard>
          </StyledMainWrapperCard>
          <StyledBtnsWrapper>
            <Tooltip content={t("common.share") || ""} placement="left">
              <StyledIconButton
                icon="share"
                isSquared
                variant="primary"
                color="white"
                size="m"
                onClick={() => copyCurrentUrl()}
              />
            </Tooltip>
            {isReadOnly && !isElladaProfile && (
              <Button
                color="brand"
                size="middle"
                theme="primary"
                onClick={() => navigate(`/transport-claims/edit/${id}`)}
              >
                {t("complaints.edit") || ""}
              </Button>
            )}
          </StyledBtnsWrapper>
        </StyledMainTitle>
        <TrasnportComplaintsFields
          isDisabled
          isDealerProfileType={isDealerProfileType}
          isReadOnly={isReadOnly}
          form={form}
          isDistributorProfileType={isDistributorProfileType}
          // @ts-ignore
          record={detailRecord}
        />
      </FormProvider>
      <Space size={16}>
        <StyledButton onClick={handleCancelClick}>
          {t("common.back") || ""}
        </StyledButton>
        {
          !isReadOnly &&
          <StyledButton theme="primary" onClick={onSubmit && submit}>
            {t("common.save") || ""}
          </StyledButton>
        }
      </Space>
    </>
  );
};

export const TransportComplaintsDetail = memo(_TransportComplaintsDetail);
