import { IconButton } from "@/packages/icon-button/IconButton";
import { IHeaderItem } from "@/packages/tableSimple/TableSimple";
import { useState } from "react";
import { Datepicker } from "@/packages/formElements/inputs/Datepicker";
import { Badge, Popconfirm } from "antd";
import { PermissionsAdapter } from "@/adapters/shared/PermissionsAdapter";
import {
  TEditWarrantyPlanTasksFillingRequest,
  TWarrantyAuditTaskDto,
  TWarrantyEditStatusRequest,
} from "@/helpers/api/warranty-audit/plan/types";
import { IMessageParams } from "@/components/Audits/ChatModal";
import { TGetUserListResponse } from "@/helpers/api/users/types";
import {
  StyledData,
  StyledIconWrapper,
  StyledInfoBlock,
  StyledMessage,
  StyledSelectField,
  StyledStatusSelect,
} from "./index.styles";
import { Icon } from "@/packages/icon/Icon";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { colors } from "@/helpers";
import { TFunction } from "i18next";
import { TextAreaField } from "@/packages/formElements/fields/TextAreaField";
import { TGetWarrantyByIdResponse } from "@/helpers/api/warranty-audit/types";
import { Dispatch } from "@/components/UserInfoModal/provider";
import { StyledUserNameWrapper } from "@/components/UserInfoModal/index.styled";

export const getDefaultWarrantyPlanTableHeaders = <TRecord extends object>(options: {
  t: TFunction;
  responsible?: TGetUserListResponse;
  onDelete: (id: number) => void;
  openChat: (params: IMessageParams, task: TWarrantyAuditTaskDto) => void;
  onEditField: ({
                  taskId, request,
                }: {
    taskId: number; request: TEditWarrantyPlanTasksFillingRequest;
  }) => void;
  auditInfo: TGetWarrantyByIdResponse;
  openUserModal: Dispatch;
}): IHeaderItem<TWarrantyAuditTaskDto>[] => {
  const { t, responsible, auditInfo, openChat, onEditField, onDelete, openUserModal } = options;

  const usersResponsible = responsible ? responsible?.data.map((el) => ({
    label: `${el.firstName} ${el.lastName}`, value: el.id,
  })) : [];

  return [
    {
      Header: t("warrantyAudits.list.idTaskVisit") || "",
      accessor: "id",
      width: 80,
      minWidth: 80,
      maxWidth: 80,
      Cell: ({ row }) => {
        //@ts-ignore
        return `${auditInfo?.visitNumber}.${+row?.id + 1}`
      },
    },
    {
      Header: t("warrantyAudits.list.section") || "",
      accessor: "violation.answer.param.section.type",
      width: 100,
      minWidth: 100,
      maxWidth: 100,
    }, {
      Header: t("warrantyAudits.list.titleTable") || "", accessor: "title",
      width: 300,
      minWidth: 300,
      maxWidth: 300,
      Cell: ({ row: { original } }) => {
        const [formValues, setFormValue] = useState(original.title);
        const handleSubmit = () => {
          onEditField({
            taskId: original.id, request: {
              title: formValues,
            },
          });
        };
        return !original.violation ? (<TextAreaField
          value={formValues}
          onChange={(value) => setFormValue(value)}
          onBlur={handleSubmit}
          onPressEnter={handleSubmit}
        />) : (original.violation.answer.param.title);
      },
    }, {
      Header: t("warrantyAudits.list.task") || "",
      accessor: "task",
      width: 150,
      minWidth: 150,
      maxWidth: 150,
      Cell: ({ row: { original } }) => {
        const [formValues, setFormValue] = useState(original.task);
        const handleSubmit = () => {
          onEditField({
            taskId: original.id, request: {
              task: formValues,
            },
          });
        };
        return (<TextAreaField
          value={formValues}
          onChange={(value) => setFormValue(value)}
          onBlur={handleSubmit}
          onPressEnter={handleSubmit}
        />);
      },
    },

    {
      Header: t("warrantyAudits.list.responsible") || "",
      accessor: "responsible",
      width: 200,
      minWidth: 200,
      maxWidth: 200,
      Cell: ({ row: { original } }) => {
        return (<StyledSelectField
          options={usersResponsible}
          value={usersResponsible.find((item) => item.value === original.responsibleId?.toString())}
          defaultValue={usersResponsible.find((item) => item.value === original.responsibleId?.toString()) || null}
          onChange={(value) => onEditField({
            taskId: original.id, request: {
              responsibleId: value,
            },
          })}
          onClick={() => openUserModal({
            userId: usersResponsible.find(
              (item) => item.value === original.responsibleId?.toString()
            )?.value ?? ''
          })}
        />);
      },
    }, {
      Header: t("warrantyAudits.list.deadline") || "",
      accessor: "deadline",
      width: 150,
      minWidth: 150,
      maxWidth: 150,
      Cell: ({ row: { original } }) => {
        return (<Datepicker
          value={original.deadline ? new Date(original.deadline) : null}
          onChange={(value) => {
            onEditField({
              taskId: original.id, request: {
                // @ts-ignore
                deadline: value ? new Date(value).toISOString() : null,
              },
            });
          }}
        />);
      },
    }, {
      Header: "",
      id: "actions",
      width: 80,
      minWidth: 80,
      maxWidth: 80,
      Cell: ({ row: { original } }) => {
        const unreadCount = original.unreadMessagesAmount;
        const allCount = original.allMessagesAmount;
        return (
          <StyledIconWrapper>
            <Badge color={unreadCount === 0 ? '#808080' : undefined} count={unreadCount > 0 ? unreadCount : allCount}>
              <IconButton
                icon="chat"
                color="black"
                size="l"
                variant="tertiary"
                onClick={() => openChat({ taskId: original.id }, original)}
              /></Badge>
            <PermissionsAdapter
              resourceName="audit.task-managment"
              resourceAction="delete"
            >
              <Popconfirm
                title={t("warrantyAudits.list.confirm") || ""}
                onConfirm={() => onDelete && onDelete(original.id)}
                okText={t("deleteConfirm.yes") || ""}
                cancelText={t("deleteConfirm.cancel") || ""}
              >
                <IconButton
                  icon="delete"
                  color="black"
                  size="l"
                  variant="tertiary"
                />
              </Popconfirm>
            </PermissionsAdapter>
          </StyledIconWrapper>);
      },
    },];
};

const MOCK_STATUS_VALUES = [{ label: "Принято", value: "accepted" }, {
  label: "В работе у дилера",
  value: "in_work"
}, { label: "Проверить", value: "review" },];

const statusToText = {
  accepted: { color: "#2F4A04", background: "#B5D880", message: "Принято" }, review: {
    background: "#7B7D81", color: "#DBDCE0", message: "warrantyAudits.list.comm",
  }, in_work: {
    color: "#B06E0A", background: "#F5DA69", hasIcon: true, message: "warrantyAudits.list.perform",
  }, draft: {
    background: "#7B7D81", color: "#DBDCE0", message: "warrantyAudits.list.expected",
  },
};

export const TaskStatus: React.FC<{ status: string; t: TFunction }> = (props) => {
  const { status, t } = props;

  return (<StyledInfoBlock>
    <StyledMessage
      background={statusToText[status]?.background}
      color={statusToText[status]?.color}
    >
      {t(statusToText[status]?.message) || ""}
    </StyledMessage>
    {statusToText[status]?.hasIcon ? (<Icon name="info" color="yellowStatusBg" size={16}/>) : (<></>)}
  </StyledInfoBlock>);
};

export const getDefaultWarrantyPlanTableHeadersView = <
    TRecord extends object
  >(options: {
    t: TFunction;
    responsible?: TGetUserListResponse;
    isDealerKia: boolean;
    openChat: (params: IMessageParams, task: TWarrantyAuditTaskDto) => void;
    onChangeStatus: (taskId: number, request: TWarrantyEditStatusRequest) => void;
    openUserModal: Dispatch;
    auditInfo: TGetWarrantyByIdResponse;
  }): IHeaderItem<TWarrantyAuditTaskDto>[] => {
    const { t, responsible, isDealerKia, openChat, onChangeStatus, auditInfo, openUserModal } = options;

    return [
      {
        Header: t("warrantyAudits.list.idTaskVisit") || "",
        accessor: "id",
        width: 80,
        minWidth: 80,
        maxWidth: 80,
        Cell: ({ row }) => {
          //@ts-ignore
          return `${auditInfo?.visitNumber}.${+row?.id + 1}`
        },
      },
      {
        Header: t("warrantyAudits.list.section") || "",
        accessor: "violation.answer.param.section.type",
        width: 100,
        minWidth: 100,
        maxWidth: 100,
      },
      {
        Header: t("warrantyAudits.list.titleTable") || "",
        accessor: "title",
        width: 450,
        minWidth: 450,
        maxWidth: 450,
      }
      ,
      {
        Header: t("warrantyAudits.list.task") || "",
        accessor: "task",
        width: 150,
        minWidth: 150,
        maxWidth: 150,
      }
      ,
      {
        Header: t("warrantyAudits.list.responsible") || "",
        accessor: "responsible",
        width: 100,
        minWidth: 100,
        maxWidth: 100,
        Cell:
          ({ row: { original } }) => {
            const userResponsible = responsible?.data?.find((item) => item?.id === original?.responsibleId);

            return (<StyledUserNameWrapper
              onClick={() => openUserModal({ userId: userResponsible?.id ?? '' })}>{`${userResponsible?.firstName || ""} ${userResponsible?.lastName || ""}`}</StyledUserNameWrapper>);
          },
      },
      {
        Header: t("warrantyAudits.list.deadline") || "",
        accessor: "deadline",
        width: 120,
        minWidth: 120,
        maxWidth: 120,
        Cell:
          ({ value }) => {
            const isOld = new Date() > new Date(value);
            return value ? (<StyledData
              style={{
                color: isOld ? "#E32446" : "black",
              }}
            >
              <Icon
                size={16}
                name="calendar-16"
                color={isOld ? "redStatus10" : "black"}
              />
              <Paragraph size={12} weight={300}>
                {new Date(value).toLocaleDateString()}
              </Paragraph>
            </StyledData>) : ("-");
          },
      },
      {
        Header: t("audits.templates.status_2") || "",
        accessor: "status",
        width: 150,
        minWidth: 150,
        maxWidth: 150,
        Cell:
          ({ row: { original }, value }) => {
            // "in_work" | "accepted" | "review" | "declined";
            let color;
            switch (value) {
              case "review": {
                color = colors.yellow;

                break;
              }
              case "in_work": {
                color = colors.gray100;

                break;
              }
              case "accepted": {
                color = colors.green100;
                break;
              }
            }

            return isDealerKia ? (<StyledStatusSelect
              options={MOCK_STATUS_VALUES.filter((item) => !!item.value)}
              value={MOCK_STATUS_VALUES.find((item) => item.value === value?.toString()) || []}
              defaultValue={original?.status || null}
              onChange={(value) => {
                onChangeStatus(original.id, { status: value });
              }}
              color={color}
            />) : (<TaskStatus status={original.status} t={t}/>);
          },
      },
      {
        Header: "",
        id: "actions",
        width: 50,
        minWidth: 50,
        maxWidth: 50,
        Cell:
          ({ row: { original } }) => {
            return (
              <Badge count={original.allMessagesAmount}>
                <IconButton
                  icon="chat"
                  color="black"
                  size="l"
                  variant="tertiary"
                  onClick={() => openChat({ taskId: original.id }, original)}
                />
              </Badge>
            );
          },
      }
      ,]
      ;
  }
;
