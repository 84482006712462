import { NestedTable } from "@/packages/nestedTable/NestedTable";
import styled from "styled-components";
import { colors } from "@/helpers";

export const StyledAutoParkTabWrapper = styled.div`
  background-color: ${colors.white};
`;

export const StyledAutoParkDescWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 40px 14px 22px;
`;

export const StyledAutoParkDesc = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
