import { usePostInformationLetterAgain } from "@/helpers/api/InformationalLetters/hooks";
import { TInformationLettersStatisticRecord } from "@/helpers/api/InformationalLetters/types";
import { useNotification } from "@/hooks/useNotification";
import { usePermission } from "@/hooks/usePermission";
import { IHeaderItem } from "@/packages/tableSimple/TableSimple";
import { Space } from "antd";
import { Button } from "antd/lib/radio";
import React from "react";
import { useParams } from "react-router-dom";
import { StyledTable } from "./index.styles";
import { useTranslation } from "react-i18next";
import { formatDate } from "@/helpers/formatDate";

export interface IStatisticTable {
  data?: TInformationLettersStatisticRecord[];
}

export const StatisticTable: React.FC<IStatisticTable> = (props) => {
  const { data } = props;
  const params = useParams();
  const { hasAccess } = usePermission("dc", "update");

  const { createNotificationSuccess, createNotificationError } =
    useNotification();
  const { t } = useTranslation();
  const {
    mutateAsync: postInformationLetterAgainAsync,
    isLoading: isLoadingPostInformationLetter,
  } = usePostInformationLetterAgain();

  const headers: IHeaderItem<TInformationLettersStatisticRecord>[] = [
    {
      Header: t("dealerMaterials.letters.dealerName") || "",
      accessor: "dealer.merchantName",
      width: 150,
    },

    {
      Header: t("dealerMaterials.letters.city") || "",
      accessor: "dealer.city.name",
      width: 150,
    },

    {
      Header: t("dealerMaterials.letters.warrantyCode") || "",
      accessor: "dealer.warrantyCode",
      width: "200px",
      minWidth: 200,
      maxWidth: 200,
    },
    {
      Header: t("dealerMaterials.letters.sapCode") || "",
      accessor: "dealer.sapCode",
      width: 150,
    },
    {
      Header: t("dealerMaterials.letters.mobisCode") || "",
      accessor: "dealer.mobisCode",
      width: 150,
    },

    {
      Header: t("dealerMaterials.letters.status") || "",
      accessor: "status",
      width: 150,
    },
    {
      Header: t("dealerMaterials.letters.whoAcquainted") || "",
      accessor: "whoAcquainted",
      width: 150,
      Cell: (data) => {
        return data.row.original.familiarizeUser && data.row.original.familiarizedAt ?
          `${data.row.original.familiarizeUser.lastName} ${data.row.original.familiarizeUser.firstName} 
        / 
        ${formatDate(data.row.original.familiarizedAt, "dateOnly")}` : '';
      }
    },
    {
      Header: "",
      width: 200,
      id: "actions",

      Cell: (data) => {
        const onClickHandler = async () => {
          try {
            await postInformationLetterAgainAsync({
              letterId: params.id as string,
              dealerId: data.row.original.dealer.id,
            });
            createNotificationSuccess(
              t(
                "dealerMaterials.letters.createNotificationSuccessLetterSend"
              ) || ""
            );
          } catch {
            createNotificationError(
              t("dealerMaterials.letters.createNotificationErrorLetterSend") ||
              ""
            );
          }
        };

        return (
          // <PermissionsAdapter resourceName="dc" resourceAction="update">
          <Space>
            <Button onClick={onClickHandler}>
              {t("dealerMaterials.letters.sendAgain") || ""}
            </Button>
          </Space>
          // </PermissionsAdapter>
        );
      },
    },
  ];
  //@ts-ignore
  return <StyledTable headers={headers} data={data ?? []}/>;
};
