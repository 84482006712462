import React from "react";
import { FormProvider } from "react-hook-form";

import { Button } from "@/packages/button/Button";
import { FormDatepickerField } from "@/packages/formElements/formFields/FormDatepickerField";

import { ModelsSortModal } from "@/components/Calendar/CalendarFilter/ModelsSortModal";

import {
  ICalendarFilterFormValues,
  TOnSubmitCalendarFilterFormCb,
  useCalendarFilterForm,
} from "./hooks";

import {
  StyledButtonsContainer,
  StyledCalendarFilterWrapper,
  StyledDash,
  StyledDatepickerWrapper,
  StyledFormLayout,
  StyledIconContainer,
} from "./index.styles";

import { getShiftedDate } from "@/helpers/date";
import { useModal } from "@/hooks/useModal";
import { Icon } from "@/packages/icon/Icon";
import { useGetModels } from "@/helpers/api/testDrives/hooks";
import { FormSelect } from "@/packages/formElements/formFields/FormSelect";
import { useTranslation } from "react-i18next";

export interface ICalendarFilter {
  onFiltersChange: TOnSubmitCalendarFilterFormCb;
  initValues: ICalendarFilterFormValues;
}

const MAX_CALENDAR_DAYS = 44;

export const CalendarFilter: React.FC<ICalendarFilter> = (props) => {
  const { onFiltersChange, initValues } = props;
  const { t } = useTranslation();
  const { form, onSubmitHandler, onClearHandler } = useCalendarFilterForm({
    initValues,
    onSubmit: onFiltersChange,
  });

  const [modelIds, startPeriodDateForExport, endPeriodDateForExport] =
    form.watch([
      "modelIds",
      "startPeriodDateForExport",
      "endPeriodDateForExport",
    ]);

  const { data: modelsData } = useGetModels();
  const models = modelsData?.data.data;

  const {
    modalState: ModelsSortModalState,
    openModal: ModelsSortOpenModal,
    closeModal: ModelsSortCloseModal,
  } = useModal();

  const handleModelsSort = (modelIds: string[]) => {
    form.setValue("modelIds", modelIds);
    ModelsSortCloseModal();
  };

  return (
    <StyledCalendarFilterWrapper>
      <FormProvider {...form}>
        <StyledFormLayout>
          <FormSelect
            name="modelIds"
            label={t("park.model") || ""}
            mode="multiple"
            placeholder={t("audits.list.all") || ""}
            options={
              models?.map((model) => ({
                label: model.name,
                value: model.id,
              })) || []
            }
            isSearchShow
          />
          <StyledIconContainer>
            <Icon name="sort" size={24} onClick={ModelsSortOpenModal} />
          </StyledIconContainer>
          <StyledDatepickerWrapper>
            <FormDatepickerField
              name="startPeriodDateForExport"
              isErrorAbsolute={true}
              label={t("park.test") || ""}
              maxDate={endPeriodDateForExport || undefined}
              minDate={undefined}
              isSameDateEnabled={true}
            />
            <StyledDash />
            <FormDatepickerField
              name="endPeriodDateForExport"
              isErrorAbsolute={true}
              minDate={startPeriodDateForExport || undefined}
              maxDate={undefined}
              isSameDateEnabled={true}
            />
          </StyledDatepickerWrapper>
        </StyledFormLayout>
      </FormProvider>

      <StyledButtonsContainer>
        <Button
          color="brand"
          theme="primary"
          size="middle"
          onClick={onSubmitHandler}
        >
          {t("audits.list.find") || ""}
        </Button>
        <Button
          color="brand"
          theme="secondary"
          size="middle"
          onClick={onClearHandler}
        >
          {t("dealerMaterials.letters.clear") || ""}
        </Button>
        <ModelsSortModal
          isOpen={ModelsSortModalState.isOpen}
          onSubmit={handleModelsSort}
          onCancel={ModelsSortCloseModal}
          models={models || []}
          selectedModelsIds={modelIds}
        />
      </StyledButtonsContainer>
    </StyledCalendarFilterWrapper>
  );
};
