import { PermissionsAdapter } from "@/adapters/shared/PermissionsAdapter";
import {
  TOnSubmitIPEditBatchFormCb,
  TOnSubmitIPEditFormCb,
} from "@/components/informationalLetters/InformationLettersCreateData/hooks";
import { InformationLettersEditBatchModal } from "@/components/informationalLetters/InformationLettersEditBatchModal";
import { InformationLettersEditModal } from "@/components/informationalLetters/InformationLettersEditModal";
import {
  useDeleteInformationLetter,
  useGetInformationLettersSectionsBatch,
  usePatchInformationLetter,
  usePatchInformationLetterBatch,
} from "@/helpers/api/InformationalLetters/hooks";
import { useNotification } from "@/hooks/useNotification";
import React, { useState } from "react";

import {
  TGetInformationLettersSectionsBatchResponse,
  TInformationLettersBatchRecord,
  TInformationLettersRecord,
} from "@/helpers/api/InformationalLetters/types";
import { useModal } from "@/hooks/useModal";
import { DashboardLayout } from "@/layouts/DashboardLayout";
import { IconButton } from "@/packages/icon-button/IconButton";
import TreeComponent from "./TreeComponent";
import { mutateArrOfObj } from "./utils";
import { useTranslation } from "react-i18next";
import { DelimitedArrayParam, useQueryParam } from "use-query-params";
import { useDebounce } from "use-debounce";
import { Space } from "antd";
import { Search } from "@/packages/formElements/inputs/search/Search";

const InformationalLetters: React.FC = () => {
  const { createNotificationSuccess, createNotificationError } =
    useNotification();
  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState<string>('');
  const [debouncedSearchValue] = useDebounce(searchValue, 800);

  const {
    data: informationLettersSectionsBatch,
    refetch: informationLettersSectionsBatchRefetch,
  } = useGetInformationLettersSectionsBatch({
    search: debouncedSearchValue,
  });

  const { mutateAsync: patchInformationLetterAsync } =
    usePatchInformationLetter();

  const { mutateAsync: patchInformationLetterBatchAsync } =
    usePatchInformationLetterBatch();

  const { mutateAsync: deleteIPAsync } = useDeleteInformationLetter();

  const onCreateBatchIP: TOnSubmitIPEditBatchFormCb = async (data) => {
    try {
      await patchInformationLetterBatchAsync(data);
      createNotificationSuccess(
        t("dealerMaterials.letters.createNotificationSuccessIP") || ""
      );
    } catch (e) {
      createNotificationError(
        t("dealerMaterials.letters.createNotificationErrorIP") || ""
      );
      throw e;
    }
  };

  const onEditIP: TOnSubmitIPEditFormCb = async (data) => {
    try {
      await patchInformationLetterAsync(data);
      createNotificationSuccess(
        t("dealerMaterials.letters.createNotificationSuccessLetter") || ""
      );
    } catch (e) {
      createNotificationError(
        t("dealerMaterials.letters.createNotificationErrorLetter") || ""
      );
      throw e;
    }
  };

  const onDeleteIPHandler = async (data: TInformationLettersRecord) => {
    try {
      await deleteIPAsync({
        id: data.id,
      });
      createNotificationSuccess(
        t("dealerMaterials.letters.createNotificationSuccessLetterDelete") || ""
      );
    } catch (e) {
      createNotificationError(
        t("dealerMaterials.letters.createNotificationErrorLetterDelete") || ""
      );
      throw e;
    }
  };
  const {
    modalState: informationLettersEditModalState,
    openModal: openInformationLettersEditModalState,
  } = useModal<
    { id: string; patch: TInformationLettersRecord },
    { record: TInformationLettersRecord }
  >((close) => {
    return async (payload) => {
      await onEditIP(payload);
      informationLettersSectionsBatchRefetch();
      close();
    };
  });

  const {
    modalState: informationLettersEditBatchModalState,
    openModal: openInformationLettersEditBatchModalState,
  } = useModal<TInformationLettersBatchRecord>((close) => {
    return async (payload) => {
      await onCreateBatchIP(payload);
      close();
    };
  });

  const batchSections = mutateArrOfObj(
    informationLettersSectionsBatch?.data
      .data as unknown as TGetInformationLettersSectionsBatchResponse
  );

  const [activeMaterialKeys, setActiveMaterialKeys] = useQueryParam('active', DelimitedArrayParam);

  return (
    <DashboardLayout
      title={t("dealerMaterials.letters.description") || ""}
      headerLeft={
        <Space>
          <Search
            value={searchValue}
            onChange={(value) => setSearchValue(value || "")}
          />
        </Space>
      }
      headerRight={
        <PermissionsAdapter
          resourceName="information-letters"
          resourceAction="update"
        >
          <IconButton
            variant="tertiary"
            icon="edit"
            color="brand"
            onClick={() => openInformationLettersEditBatchModalState()}
          >
            {t("dealerMaterials.letters.edit") || ""}
          </IconButton>
        </PermissionsAdapter>
      }
    >
      <TreeComponent
        activeMaterialKeys={activeMaterialKeys}
        setActiveMaterialKeys={setActiveMaterialKeys}
        data={batchSections}
        openInformationLettersEditModalState={
          openInformationLettersEditModalState
        }
        onDeleteIPHandler={onDeleteIPHandler}
        searchValue={debouncedSearchValue}
      />

      <InformationLettersEditModal {...informationLettersEditModalState} />
      {/* //@ts-ignore */}
      <InformationLettersEditBatchModal
        {...informationLettersEditBatchModalState}
      />
    </DashboardLayout>
  );
};

export default InformationalLetters;
