import { FormSelect } from "@/packages/formElements/formFields/FormSelect";
import { Col } from "antd";
import React, { useContext, useMemo, useState } from "react";
import { FormProvider } from "react-hook-form";
import { useAuditFilterForm } from "./hooks";
import { Button } from "@/packages/button/Button";
import {
  ColumnWraper,
  StyledButton,
  StyledButtons,
  StyledFilters,
  StyledRow,
  StyledSeparator,
} from "./index.styles";
import { FormDatepickerField } from "@/packages/formElements/formFields/FormDatepickerField";
import {
  preparedDealerList,
} from "@/helpers/api/dc/kia-dealers/hooks";
import { IRequestParams } from "@/helpers/interfaces";
import { SelectField } from "@/packages/formElements/fields/SelectField";
import { useGetKiaHoldingList } from "@/helpers/api/dc/kia-holdings/hooks";
import { useGetAuditorsList } from "@/helpers/api/audit-list/hooks";
import { MOCK_TASK_STATUS } from "../TasksListTable/helpers";
import { useGetUserList } from "@/helpers/api/users/hooks";
import { ContextProfile } from "@/hooks/contexts/useProfile";
import { Tooltip } from "@/packages/Tooltip/Tooltip";
import { useTranslation } from "react-i18next";
import { useGetAuditResponsible } from "@/helpers/api/audit-plan/hooks";
import { useGetFilteredDealerList } from "@/helpers/api/global-filters/hooks";

export interface ITaskFilter {
  onFilterChange: (value: IRequestParams) => void;
}

const StatusFilters = [
  { label: "audits.list.all", value: "" },
  ...MOCK_TASK_STATUS,
];

const TaskFilter: React.FC<ITaskFilter> = (props) => {
  const { onFilterChange } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const { t } = useTranslation();
  const {
    state: { profile },
  } = useContext(ContextProfile);

  const isDealerKia = profile?.type === "kia";

  const { data: users, isLoading: isUsersLoading } = useGetUserList({
    permissionSlug: "audit.implementation",
    permissionAction: "update",
    limit: 0,
  });

  const { data: usersResponsible } = useGetAuditResponsible({
    "filter[withoutDrafts]": true,
    "sort[name]": "ASC",
  });

  const { data: holdingList, isLoading: isHoldingsLoading } =
    useGetKiaHoldingList({ limit: 0 });

  const { data: dealerList} =
    useGetFilteredDealerList({ q: searchValue, limit: 0 });

  const { data: auditorsList, isLoading: isAuditorsLoading } =
    useGetAuditorsList({});

  const onFilterApply = (data) => {
    let params = {};
    Object.entries(data).forEach(([key, value]) => {
      params = {
        ...params,
        [key]: {
          value,
          type: "filter",
        },
      };
    });
    onFilterChange(params);
  };

  const { form, onClearHandler, onSubmitHandler } =
    useAuditFilterForm(onFilterApply);

  const clearFilters = () => {
    onClearHandler();
    onFilterApply({});
  };

  const preparedHoldingList = useMemo(
    () =>
      holdingList?.data?.data?.map((item) => ({
        label: item.name,
        value: item.id,
      })) || [],
    [holdingList?.data, isHoldingsLoading]
  );

  const preparedAuditorsList = useMemo(
    () =>
      auditorsList?.data?.data?.map((item) => ({
        label: `${item.firstName}  ${item.lastName}`,
        value: item.id,
      })) || [],
    [auditorsList?.data, isAuditorsLoading]
  );

  const mappedUsers = useMemo(
    () =>
      usersResponsible?.data.data.map((item) => ({
        label: `${item.firstName} ${item.lastName}`,
        value: item.id,
      })) || [],
    [usersResponsible]
  );

  return (
    <StyledFilters isOpen={isOpen}>
      <FormProvider {...form}>
        <StyledRow gutter={16} space={24}>
          <Col xs={8} md={4}>
            <SelectField
              label={t("audits.tasks.status") || ""}
              options={StatusFilters}
              placeholder={t("audits.list.all") || ""}
              onChange={(value) => {
                onFilterChange({
                  status: {
                    value,
                    type: "filter",
                  },
                });
              }}
            />
          </Col>

          <Col>
            <Tooltip content={t("audits.tasks.filters") || ""}>
              <StyledButton
                icon="filter"
                color="black"
                size="m"
                isSquared={true}
                variant={isOpen ? "primary" : "secondary"}
                onClick={() => setIsOpen((prev) => !prev)}
              />
            </Tooltip>
          </Col>
        </StyledRow>
        <StyledRow gutter={16} space={16}>
          {isDealerKia ? (
            <Col xs={12} md={8} xxl={6}>
              <FormSelect
                name="holdingId"
                label={t("audits.list.holdingId") || ""}
                options={preparedHoldingList}
                isSearchShow={true}
                allowClear={true}
                placeholder={t("audits.list.choose") || ""}
              />
            </Col>
          ) : (
            <></>
          )}
          {isDealerKia ? (
            <Col xs={12} md={9} xxl={6}>
              <FormSelect
                name="dealerId"
                label={t("audits.list.dealerId") || ""}
                options={
                  preparedDealerList(dealerList?.data?.data || []) || []
                }
                placeholder={t("audits.list.chooseList") || ""}
                isSearchShow
                allowClear
                onSearch={setSearchValue}
              />
            </Col>
          ) : (
            <></>
          )}
        </StyledRow>
        <StyledRow gutter={16} space={16}>
          <Col xs={12} md={8} xxl={6}>
            <FormSelect
              name="auditorId"
              label={t("audits.list.auditorId") || ""}
              options={preparedAuditorsList}
              allowClear={true}
              placeholder={t("audits.list.choose") || ""}
            />
          </Col>

          {isDealerKia ? (
            <Col xs={12} md={8} xxl={6}>
              <FormSelect
                name="responsibleId"
                label={t("audits.tasks.responsibleId") || ""}
                options={mappedUsers}
                isSearchShow={true}
                allowClear={true}
                placeholder={t("audits.list.choose") || ""}
              />
            </Col>
          ) : (
            <></>
          )}
          <Col xs={24} md={8} xxl={6}>
            <ColumnWraper>
              <FormDatepickerField
                name="deadlineStart"
                label={t("audits.tasks.makeBefore") || ""}
              />
              <StyledSeparator />
              <FormDatepickerField name="deadlineEnd" label={" "} />
            </ColumnWraper>
          </Col>
        </StyledRow>
        <StyledButtons>
          <Button
            color="brand"
            theme="primary"
            size="middle"
            onClick={onSubmitHandler}
          >
            {t("audits.list.find") || ""}
          </Button>
          <Button
            color="brand"
            theme="secondary"
            size="middle"
            onClick={clearFilters}
          >
            {t("audits.list.clear") || ""}
          </Button>
        </StyledButtons>
      </FormProvider>
    </StyledFilters>
  );
};

export default TaskFilter;
