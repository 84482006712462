import React from "react";

import { Search } from "@/packages/formElements/inputs/search/Search";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { Title } from "@/packages/title/Title";

import { StyledAutoParkDescWrapper, StyledAutoParkDesc } from "./index.styles";

export interface IAutoParkDesc {
  title: string;
  description: string;
  searchValue?: string;
  handleSearch: (value?: string) => void;
}

export const AutoParkDesc: React.FC<IAutoParkDesc> = (props) => {
  const { title, description, searchValue, handleSearch } = props;

  return (
    <StyledAutoParkDescWrapper>
      <StyledAutoParkDesc>
        <Title level={4} weight={300}>
          {title}
        </Title>
        {/* TODO: вывести, когда будут статусы */}
        {/* <Paragraph color="gray200" size={10} weight={300}>
          {description}
        </Paragraph> */}
      </StyledAutoParkDesc>
      <Search value={searchValue} onChange={(value) => handleSearch(value)} />
    </StyledAutoParkDescWrapper>
  );
};
