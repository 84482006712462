import { useGetProfile } from "@/helpers/api/profile/hooks";
import { FormFileDropzoneField } from "@/packages/formElements/formFields/FormFileDropzoneField";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { UploadType } from "@/packages/UploadImageAndFileDropzone";
import React, { FC, memo, useState } from "react";
import { TTransportRecordResponse } from "../types";
import { StyledFormSelect, StyledViewWrapper, } from "./index.styles";
import { TransportComplaintsDetailCard } from "./TransportComplaintsDetailCard";
import { TransportComNameFields, TransportComPath } from "./types";
import { useTranslation } from "react-i18next";
import { FormCheckbox } from "@/packages/formElements/formFields/FormCheckbox";
import { UseFormReturn } from "react-hook-form";
import { ITransportComDetailFormValues } from "@/components/TransportComplaints/TransportComplaintsDetail/hooks";
import { preparedDealerList } from "@/helpers/api/dc/kia-dealers/hooks";
import { useGetFilteredDealerList } from "@/helpers/api/global-filters/hooks";
import { holdingRoles } from "@/components/TransportComplaints/TransportComplaintsFilter";

interface TransportComplaintsFieldsProps {
  isDisabled?: boolean;
  record?: Partial<TTransportRecordResponse>;
  mode?: "other" | "create";
  form: UseFormReturn<ITransportComDetailFormValues, any>;
  isReadOnly?: boolean;
  isDealerProfileType: boolean;
  isDistributorProfileType: boolean;
}

const allowedTypeFiles = [
  "application/pdf",
  "image/jpeg",
  "image/png",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

const _TrasnportComplaintsFields: FC<TransportComplaintsFieldsProps> = (
  props
) => {
  const { t } = useTranslation();
  const { isDisabled, record, mode = "other", form, isReadOnly } = props;
  const [searchValue, setSearchValue] = useState("");

  const { data: dealerList } =
    useGetFilteredDealerList({ q: searchValue, limit: 0 });

  const profile = useGetProfile();
  const openedAtOptions = [
    { label: "Kia", value: "Kia" },
    { label: "Dealer", value: "Dealer" },
  ];

  const tempDealerOptions = [
    {
      label: profile.data?.data.dealer?.merchantName,
      value: profile.data?.data.dealer?.id,
    },
  ];

  const onRemoveFunc = (field: any, id: any) => {
    if (!isReadOnly) {
      form.setValue(
        // @ts-ignore
        `${field}.files`,
        form.getValues(field)?.files?.filter((el) => el.id !== id)
      );
    }
  };

  const getDealerOptions = () => {
    if (holdingRoles.includes(profile?.data?.data?.role?.slug ?? '')) {
      return preparedDealerList(dealerList?.data?.data || [])
    }
    return (props.isDealerProfileType ? tempDealerOptions : preparedDealerList(dealerList?.data?.data || []));
  }
  const isElladaProfile = profile?.data?.data?.role?.slug === "ellada";

  return (
    <>
      {mode === "create" && (
        <TransportComplaintsDetailCard
          textMode={true}
          title={t("complaints.complaintsForm.dealer") || ""}
        >
          <StyledFormSelect
            name={TransportComNameFields.DEALED_ID}
            isDisabled={isDisabled}
            onSearch={setSearchValue}
            isSearchShow
            allowClear
            // @ts-ignore
            options={getDealerOptions()}
          />
        </TransportComplaintsDetailCard>
      )}
      {mode === "other" && (
        <TransportComplaintsDetailCard textMode={true} title="Дилер">
          <Paragraph size={14}>
            {/* @ts-ignore */}
            {record?.dealer?.mobisCode} / {record?.dealer?.warrantyCode} / {record?.dealer?.merchantName} / {record?.dealer?.lawName}
          </Paragraph>
        </TransportComplaintsDetailCard>
      )}
      <TransportComplaintsDetailCard
        title="TTH/CMR*"
        subTitle={t("complaints.complaintsForm.tthDescr") || ""}
      >
        <FormFileDropzoneField
          maxCount={2}
          name={TransportComNameFields.TTH_CMR}
          path={TransportComPath.TTH_CMR}
          isDisabled={isReadOnly}
          iconDelete={!isReadOnly}
          fileIds={mode === "other" ? record?.tthCmr?.map(it => it.file) : []}
          variantBtn="contained"
          typeInfo={UploadType.UNIVERSAL}
          onRemove={(id) => {
            onRemoveFunc("tthCmr", id);
          }}
          allowedTypesProps={allowedTypeFiles}
        />
      </TransportComplaintsDetailCard>
      <TransportComplaintsDetailCard
        title={t("complaints.complaintsForm.report") || ""}
        subTitle={t("complaints.complaintsForm.reportDescr") || ""}
      >
        <FormFileDropzoneField
          maxCount={3}
          name={TransportComNameFields.OUPTS}
          path={TransportComPath.OUPTS}
          isDisabled={isReadOnly}
          iconDelete={!isReadOnly}
          fileIds={mode === "other" ? record?.oupts?.map(it => it.file) : []}
          variantBtn="contained"
          typeInfo={UploadType.UNIVERSAL}
          minHeight={1200}
          minWidth={1200}
          onRemove={(id) => {
            onRemoveFunc("oupts", id);
          }}
          allowedTypesProps={allowedTypeFiles}
        />
      </TransportComplaintsDetailCard>

      <TransportComplaintsDetailCard
        title={t("complaints.complaintsForm.vin") || ""}
        subTitle={t("complaints.complaintsForm.vinDescr") || ""}
      >
        <FormFileDropzoneField
          maxCount={3}
          name={TransportComNameFields.VIN_PLATE}
          path={TransportComPath.VIN_PLATE}
          isDisabled={isReadOnly}
          iconDelete={!isReadOnly}
          fileIds={mode === "other" ? record?.vinPlate?.map(it => it.file) : []}
          variantBtn="contained"
          typeInfo={UploadType.UNIVERSAL}
          onRemove={(id) => {
            onRemoveFunc("vinPlate", id);
          }}
          allowedTypesProps={allowedTypeFiles}
        />
      </TransportComplaintsDetailCard>
      <TransportComplaintsDetailCard
        title={t("complaints.complaintsForm.viewAuto") || ""}
        subTitle={t("complaints.complaintsForm.viewAutoDescr") || ""}
      >
        <FormFileDropzoneField
          name={TransportComNameFields.GENERAL_VIEW}
          path={TransportComPath.GENERAL_VIEW}
          isDisabled={isReadOnly}
          iconDelete={!isReadOnly}
          fileIds={mode === "other" ? record?.generalView?.map(it => it.file) : []}
          variantBtn="contained"
          typeInfo={UploadType.UNIVERSAL}
          onRemove={(id) => {
            onRemoveFunc("generalView", id);
          }}
          allowedTypesProps={allowedTypeFiles}
        />
      </TransportComplaintsDetailCard>
      <TransportComplaintsDetailCard
        title={t("complaints.complaintsForm.viewParts") || ""}
        subTitle={t("complaints.complaintsForm.viewPartsDescr") || ""}
      >
        <FormFileDropzoneField
          name={TransportComNameFields.VIEW_DETAIL}
          path={TransportComPath.VIEW_DETAIL}
          isDisabled={isReadOnly}
          iconDelete={!isReadOnly}
          fileIds={mode === "other" ? record?.viewDetail?.map(it => it.file) : []}
          variantBtn="contained"
          typeInfo={UploadType.UNIVERSAL}
          onRemove={(id) => {
            onRemoveFunc("viewDetail", id);
          }}
          allowedTypesProps={["image/png", "image/jpeg"]}
          withCommentField={true}
        />
      </TransportComplaintsDetailCard>

      <TransportComplaintsDetailCard
        title={t("complaints.complaintsForm.other") || ""}
        subTitle={t("complaints.complaintsForm.otherDescr") || ""}
      >
        <FormFileDropzoneField
          name={TransportComNameFields.OTHER}
          path={TransportComPath.OTHER}
          fileIds={mode === "other" ? record?.other?.map(it => it.file) : []}
          variantBtn="contained"
          typeInfo={UploadType.UNIVERSAL}
          isDisabled={isReadOnly}
          iconDelete={!isReadOnly}
          onRemove={(id) => {
            onRemoveFunc("other", id);
          }}
          allowedTypesProps={allowedTypeFiles}
          withCommentField={true}
        />
      </TransportComplaintsDetailCard>
      {
        (!props.isDealerProfileType || props.isDistributorProfileType) && !isElladaProfile &&
        <TransportComplaintsDetailCard
          textMode={true}
          title={t("complaints.open") || ""}
        >
          <StyledViewWrapper>
            {
              !props.isDistributorProfileType &&
              <StyledFormSelect
                label={t("complaints.open") || ""}
                name={TransportComNameFields.OPEN_FOR_VIEWING}
                options={openedAtOptions}
                isDisabled={isReadOnly}
              />
            }
            <FormCheckbox
              name={TransportComNameFields.IS_ELLADA}
              label={t("complaints.ellada") || ""}
              isDisabled={isReadOnly}
            />
          </StyledViewWrapper>
        </TransportComplaintsDetailCard>
      }
    </>
  );
};

export const TrasnportComplaintsFields = memo(_TrasnportComplaintsFields);
