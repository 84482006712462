import { TGetProfileResponse, TRoleSlug } from '@/helpers/api/profile/types';
import { TUserRecord } from '@/helpers/api/users/types';
import { isHeadUserBySlug } from '@/helpers/utils';
import { ContextProfile } from '@/hooks/contexts/useProfile';
import { Switch } from '@/packages/formElements/inputs/switch';
import { IconButton } from '@/packages/icon-button/IconButton';
import { IHeaderItem, ITableSimple, TableSimple } from '@/packages/tableSimple/TableSimple';
import { Popconfirm } from 'antd';
import React, { useContext } from 'react';
import { t } from 'i18next';

const getHeaders = (options: {
    profile?: TGetProfileResponse;
    isDisabled: boolean;
    onChangeIsActive: (record: TUserRecord, value: boolean) => Promise<any>;
    onDeleteClick: (userId: string) => void;
}): IHeaderItem<TUserRecord>[] => {
    const isHeadUser = (record: TUserRecord) =>
        isHeadUserBySlug(record.role.slug as TRoleSlug);
    const isOneself = (record: TUserRecord) =>
        options?.profile?.id === record?.id;
    const hasActions = (record: TUserRecord) =>
        !isOneself(record) && !isHeadUser(record);

    const dealerUserPermission = options?.profile?.permissions?.filter(
        (elem) => elem.name === 'user.managment',
    );

    const isGeneralDirector = options?.profile?.role?.slug === 'generalnyj-direktor';

    const dealerUserUpdatePermission =
        dealerUserPermission && dealerUserPermission[0]?.update === true;

    const dealerUserDeletePermission =
        dealerUserPermission && dealerUserPermission[0]?.delete === true;

    return [
        {
            Header: t('registers.registersDC.nameFull') || '',
            id: 'fio',
            minWidth: 200,
            Cell(data) {
                return `${data.row.original.firstName} ${data.row.original.lastName}`;
            },
        },

        {
            Header: t('registers.registersDC.roleName') || '',
            id: 'roleName',
            minWidth: 200,
            Cell(data) {
                return data.row.original.role.name;
            },
        },
        {
            Header: t('registers.registersDC.email') || '',
            accessor: 'email',
            minWidth: 200,
        },
        {
            Header: t('registers.registersDC.phone') || '',
            accessor: 'phone',
            minWidth: 200,
        },
        {
            Header: t('registers.registersDC.isActive') || '',
            accessor: 'isActive',
            width: '100px',
            minWidth: 100,
            maxWidth: 100,
            Cell: (data) => {
                const [ isLoading, setIsLoading ] = React.useState(false);
                const record = data.row.original;

                const onChangeHandler = async (value: boolean) => {
                    try {
                        setIsLoading(true);
                        await options.onChangeIsActive(record, value);
                    } finally {
                        setIsLoading(false);
                    }
                };
                return hasActions(record) &&
                (dealerUserUpdatePermission || dealerUserDeletePermission) ? (
                    <div>
                        <Switch
                            disabled={options.isDisabled || isLoading}
                            loading={isLoading}
                            value={data.value}
                            onChange={onChangeHandler}
                        />
                    </div>
                ) : (
                    <></>
                );
            },
        },
        {
            Header: '',
            id: 'actions',
            width: '50px',
            minWidth: 50,
            maxWidth: 50,

            Cell: (data) => {
                const record = data.row.original;

                const onDeleteClickHandler = () => {
                    options.onDeleteClick(data.row.original.id);
                };
                return hasActions(record) &&
                !isGeneralDirector &&
                !options.isDisabled &&
                dealerUserDeletePermission ? (
                    <Popconfirm
                        title={t('registers.registersDC.deleteQuestion') || ''}
                        onConfirm={onDeleteClickHandler}
                        okText={t('registers.registersDC.yes') || ''}
                        cancelText={t('registers.registersDC.no') || ''}
                    >
                        <IconButton
                            icon='delete'
                            color='brand'
                            size='l'
                            variant='tertiary'
                        />
                    </Popconfirm>
                ) : (
                    <></>
                );
            },
        },
    ];
};

export interface IRegistryTable {
    onChangeSelectedItems: ITableSimple<TUserRecord>['onRowsChecked'];
    data: TUserRecord[];
    isDisabled?: boolean;
    onChangeIsActive: (record: TUserRecord, value: boolean) => any;
    onDeleteClick: (userId: string) => void;
}

export const EmployeeTable: React.FC<IRegistryTable> = (props) => {
    const {
        onChangeSelectedItems,
        data,
        isDisabled = false,
        onChangeIsActive,
        onDeleteClick,
    } = props;

    const {
        state: { profile },
    } = useContext(ContextProfile);

    const headers = getHeaders({
        profile,
        onChangeIsActive,
        onDeleteClick,
        isDisabled,
    });

    const dataEmail = data.map((item) => {
        return { ...item, email: item.email._address };
    });

    const handleRowsChecked = (ids: string[]) => {
        if (!ids.length) {
            return;
        } else {
            if (onChangeSelectedItems) {
                onChangeSelectedItems(ids);
            }
        }
    };

    return (
        <TableSimple
            // @ts-ignore
            headers={headers}
            data={dataEmail}
            showCheckbox={!isDisabled}
            getRowId={(x) => x.id}
            onRowsChecked={handleRowsChecked}
        />
    );
};
