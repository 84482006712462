import { useState } from "react";
import { useModal } from "@/hooks/useModal";
import {
  StyledColoredLabel,
  StyledComment,
  StyledIntersectionThumb,
} from "./index.styles";
import { CalendarApplicationModal } from "@/components/Calendar/CalendarTable/CalendarTableColumnRight/CalendarApplication/CalendarApplicationModal";
import { Tooltip } from "@/packages/Tooltip/Tooltip";

interface ICalendarApplication {
  id: string;
  days: number;
  color: string;
  title: string;
  comment: string;
  isIntersecting: boolean;
  isIntersectingByStart?: boolean;
}

export const CalendarApplication: React.FC<ICalendarApplication> = (props) => {
  const {
    id,
    days,
    color,
    title,
    comment,
    isIntersecting,
    isIntersectingByStart,
  } = props;
  const [isHovering, setIsHovering] = useState(false);
  const {
    modalState: CalendarApplicationModalState,
    openModal: CalendarApplicationOpenModal,
    closeModal: CalendarApplicationCloseModal,
  } = useModal();

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const handleApplicationButtonClick = (e) => {
    e.stopPropagation();
    CalendarApplicationOpenModal();
  };

  const handleApplicationModalClose = () => {
    CalendarApplicationCloseModal();
  };

  return (
    <>
      {comment ? (
        <Tooltip content={<StyledComment>{comment}</StyledComment>}>
          <div>
            <StyledColoredLabel
              color={color}
              days={days}
              onMouseOver={handleMouseOver}
              onMouseOut={handleMouseOut}
              onClick={handleApplicationButtonClick}
            >
              {title}
            </StyledColoredLabel>
            {isIntersecting && (
              <StyledIntersectionThumb
                isIntersectingByStart={isIntersectingByStart}
              />
            )}
          </div>
        </Tooltip>
      ) : (
        <div>
          <StyledColoredLabel
            color={color}
            days={days}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            onClick={handleApplicationButtonClick}
          >
            {title}
          </StyledColoredLabel>
          {isIntersecting && (
            <StyledIntersectionThumb
              isIntersectingByStart={isIntersectingByStart}
            />
          )}
        </div>
      )}
      <CalendarApplicationModal
        id={id}
        isOpen={CalendarApplicationModalState.isOpen}
        closeModal={CalendarApplicationCloseModal}
        onCancel={handleApplicationModalClose}
      />
    </>
  );
};
