import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { api } from "../axiosInstance";
import {
  TGetEventLogRequest,
  TGetEventLogResponse,
  TGetMaintananceGridEventLogRequest,
  TGetMaintananceGridEventLogResponse,
} from "./types";

export const useGetEventLog = (request: any) => {
  return useQuery(["eventLogs", request], () => {
    return api.get<TGetEventLogResponse>("/event-logs/models", {
      params: request,
    });
  });
};

export const useGetUsersLog = (request: any) => {
  return useQuery(["eventLogs", request], () => {
    return api.get<TGetEventLogResponse>("/event-logs/users", {
      params: request,
    });
  });
};

export const useGetMaintanaceGridEventLog = (
  request: TGetMaintananceGridEventLogRequest
) => {
  return useQuery(["maintanaceEventLogs", request], () => {
    return api.get<TGetMaintananceGridEventLogResponse>("/event-logs/grids", {
      params: request,
    });
  });
};

// export const useRollbackEventLog = () => {
//   const queryClient = useQueryClient();
//
//   return useMutation(
//     (data: TRollbackEventLogByIdRequest) => {
//       return api.post<TRollbackEventLogByIdResponse>(
//         `/event_logs/rollback/${data.id}`
//       );
//     },
//     {
//       onSuccess: () => {
//         queryClient.invalidateQueries(["eventLogs"]);
//       },
//     }
//   );
// };
// export const useRollbackEventLog = () => {
// 	const queryClient = useQueryClient();
//
// 	return useMutation(
// 	(data: TRollbackEventLogByIdRequest) => {
// 		return api.post<TRollbackEventLogByIdResponse>(
// 		`/event-logs/rollback/${data.id}`
// 		);
// 	},
// 	{
// 		onSuccess: () => {
// 			queryClient.invalidateQueries(["eventLogs"]);
// 		},
// 	}
// 	);
// };
