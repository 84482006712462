import { useForm } from "@/hooks/useForm";
import * as yup from "yup";
import { t } from "i18next";

export type PasswordForgotFormValues = {
  email: string;
};

const DEFAULT_PASSWORD_FORGOT_FORM_VALUES: PasswordForgotFormValues = {
  email: "",
};

export const formSchema = () => {
  return yup
    .object({
      email: yup
        .string()
        .email(t("authorization.validation.emailFormat") || "")
        .required(t("authorization.validation.email") || ""),
    })
    .required();
};

export type TOnSubmitPasswordForgotFormCb = (
  data: PasswordForgotFormValues
) => Promise<void>;

export const usePasswordForgotForm = (
  onSubmit: TOnSubmitPasswordForgotFormCb
) => {
  return useForm<PasswordForgotFormValues>({
    validateFormSchema: formSchema(),
    defaultValues: DEFAULT_PASSWORD_FORGOT_FORM_VALUES,
    onSubmit,
  });
};
